import React, {useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Row, Col} from 'components/bootstrap';
import styled from 'styled-components/macro';
import {get, debounce} from 'lodash';

import { AppState } from 'reducers';

import draftEntity from 'domain/entities/draftEntity';

import TIMER from 'constants/timers';
import WARNING from 'constants/literals/warnings';

import validationHelper from 'helpers/validationHelper';
import unitHelper from 'helpers/unitHelper';

import rateService from 'services/rateService';

import NumberInput from 'components/common/NumberInput/NumberInput';
import Button from 'components/common/Button';
import InputWithUnits from 'components/common/InputWithUnits';
import FormError from 'components/common/FormError';
import CurrencyInput from 'components/common/CurrencyInput';
import DomesticDeclaredValueTooltip from 'components/tooltips/DomesticDeclaredValueTooltip';
import FreightQuoteModal from 'components/get_quote/components/FreightQuoteModal';

import {colors, mainContainerPadding} from 'styles/shared';

const StyledContainer = styled.div`
  background: ${props => (props.isOddRow ? colors.grey_concrete : 'inherit')};
  margin: 0 -${mainContainerPadding};
  padding: 1rem ${mainContainerPadding} 0;
`;

const StyledButtonContainer = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledNumberInput = styled(NumberInput)`
  margin-bottom: 0;
`;

const StyledFormError = styled(FormError)`
  margin-bottom: 2rem;
`;

interface Props {
  index: number;
  itemsCount: number;
  item: Package;
  carrier: string;
  currency: string;
  measurementSystem: string;
  isLastIndex: boolean;
  isDomestic: boolean;
  totalWeightIsUnderLimit: boolean;
  errors: object;
  errorPath: string;
  onAddPackage: () => void;
  onRemovePackage: (index: number) => void;
  onUpdatePackage: (index: number, field: string, value: string) => void;
}

function PackageItem({
  index,
  itemsCount,
  item,
  carrier,
  currency,
  measurementSystem,
  isLastIndex,
  isDomestic,
  totalWeightIsUnderLimit,
  errors,
  errorPath,
  onAddPackage,
  onRemovePackage,
  onUpdatePackage
}: Props) {
  const draft = useSelector((state: AppState) => state.draft.current.data);
  const isGuest = useSelector((state: AppState) => state.user.current?.isGuest);

  const [freightQuoteModalVisible, setFreightQuoteModalVisible] = useState(false);

  const displaySurcharge = useRef(
    debounce(() => rateService.displaySurcharge('package'), TIMER.SURCHARGE_DISPLAY_DELAY)
  ).current;

  function onChange(field, value) {
    onUpdatePackage(index, field, value);
    displaySurcharge();
  }

  function toggleFreightQuoteModal() {
    setFreightQuoteModalVisible(!freightQuoteModalVisible);
  }

  function getRestrictionError() {
    let restrictionError: JSX.Element | undefined = undefined;

    const weightIsUnderLimit = validationHelper.checkWeightIsUnderLimit(item.weight, carrier, measurementSystem);

    if (!weightIsUnderLimit || !totalWeightIsUnderLimit) {
      const weightLimits = require('data/packageLimits.json');
      const totalWeightLimits = require('data/packagesTotalWeightLimit.json');

      const weightLimit = carrier ? weightLimits?.[carrier] : undefined;
      const totalWeightLimit = carrier ? totalWeightLimits?.[carrier] : undefined;

      const unit: string = unitHelper.getUnitFromMeasurementSystem(measurementSystem, true);

      restrictionError = (
        <>
          <div>
            {carrier} has a maximum weight of{' '}
            <strong>
              {weightLimit[unit]} {unit} per piece{' '}
            </strong>
            and{' '}
            <strong>
              {totalWeightLimit[unit]} {unit} per overall shipment.
            </strong>
          </div>
          <div>
            Please break your shipment down or contact customer service for other options. We may have air freight or
            ocean freight options available.
          </div>
          <div>
            Please fill in number of packages, weight, and dimensions for all packages being shipped to receive the most accurate quote.
          </div>
          <StyledButtonContainer>
            <Button margin='0 0 0 -1.5rem' autoWidth onClick={toggleFreightQuoteModal}>
              Ask for a Freight Quote
            </Button>
          </StyledButtonContainer>
        </>
      );

      return restrictionError;
    }

    const packageExceedsLimit = validationHelper.checkPackageExceedsSizeLimit(
      [
        {
          quantity: item.numberOfPackages,
          weight: item.weight,
          length: item.length,
          width: item.width,
          height: item.height
        }
      ],
      carrier,
      isDomestic,
      measurementSystem
    );

    if (packageExceedsLimit) {
      restrictionError = (
        <>
          <div>Your package exceeds the maximum size constraints</div>
          <br />
        </>
      );
      return restrictionError;
    }

    return restrictionError;
  }

  function getDeclaredValueWarning(): JSX.Element | undefined {
    let declaredValueWarning: JSX.Element | undefined = undefined;

    if (validationHelper.checkDeclaredValueExceedsLimit(item.declaredValue, carrier)) {
      declaredValueWarning = (
        <div>
          {WARNING.DECLARED_VALUE}
        </div>
      );
    }

    return declaredValueWarning;
  }

  function render() {
    const isValid = !item.isEmpty;
    const isDeleteEnabled = itemsCount > 1;
    const isOdd = index % 2 === 1;
    const isFirstRow = index === 0;

    const restrictionError: JSX.Element | undefined = getRestrictionError();

    const declaredValueWarning: JSX.Element | undefined = getDeclaredValueWarning();

    const path = `${errorPath}[${index}]`;

    return (
      <StyledContainer isOddRow={isOdd}>
        <Row>
          <Col>
            <StyledNumberInput
              name="numberOfPackages"
              label="Number of this type of package"
              required={true}
              value={item.numberOfPackages}
              onChange={onChange}
              error={get(errors, `${path}.numberOfPackages`)}
            />
          </Col>

          <StyledButtonContainer>
            <Button theme="main" type="secondary" disabled={!isDeleteEnabled} onClick={() => onRemovePackage(index)}>
              Delete Package
            </Button>
          </StyledButtonContainer>
        </Row>

        <Row>
          <Col>
            <InputWithUnits
              name="weight"
              label="Weight"
              value={item.weight}
              measurementSystem={measurementSystem}
              isWeightUnit={true}
              required={true}
              onChange={onChange}
              skipTabForUnits={!isFirstRow}
              error={get(errors, `${path}.weight`)}
            />
          </Col>
          <Col>
            <InputWithUnits
              name="length"
              label="Length"
              value={item.length}
              measurementSystem={measurementSystem}
              required={true}
              onChange={onChange}
              skipTabForUnits={!isFirstRow}
              error={get(errors, `${path}.length`)}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <InputWithUnits
              name="width"
              label="Width"
              value={item.width}
              measurementSystem={measurementSystem}
              required={true}
              onChange={onChange}
              skipTabForUnits={!isFirstRow}
              error={get(errors, `${path}.width`)}
            />
          </Col>
          <Col>
            <InputWithUnits
              name="height"
              label="Height"
              value={item.height}
              measurementSystem={measurementSystem}
              required={true}
              onChange={onChange}
              skipTabForUnits={!isFirstRow}
              error={get(errors, `${path}.height`)}
            />
          </Col>
        </Row>

        {restrictionError && <StyledFormError message={restrictionError} />}

        {isDomestic && (
          <CurrencyInput
            name="declaredValue"
            label="Declared value"
            value={item.declaredValue}
            currency={currency}
            tooltip={DomesticDeclaredValueTooltip()}
            onChange={onChange}
            error={get(errors, `${path}.declaredValue`)}
          />
        )}

        {declaredValueWarning && <StyledFormError message={declaredValueWarning} />}

        {isLastIndex && isValid && (
          <Row>
            <Col>
              <Button autoWidth disabled={!isValid} margin="0 0 4rem" onClick={onAddPackage}>
                Add Different Package
              </Button>
            </Col>
          </Row>
        )}

        {freightQuoteModalVisible && (
          <FreightQuoteModal
            visible={freightQuoteModalVisible}
            standaloneQuote={draftEntity.mapToStandaloneQuote(draft)}
            isGuest={isGuest}
            close={toggleFreightQuoteModal}
          />
        )}
      </StyledContainer>
    );
  }

  return render();
}

export default PackageItem;
