import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';
import {Row, Col} from 'components/bootstrap';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {isEmpty} from 'lodash';

import {AppState} from 'reducers';
import pickupActions from 'actions/pickupActions';
import commonActions from 'actions/commonActions';

import SLIDER from 'constants/slider';
import SECTION from 'constants/sections';
import COUNTRY_CODE from 'constants/countryCodes/countryCodes';
import CARRIER from 'constants/carriers';

import dateHelper from 'helpers/dateHelper';
import validationHelper from 'helpers/validationHelper';
import formatHelper from 'helpers/formatHelper';
import navigationHelper from 'helpers/navigationHelper';

import StandaloneHeader from './components/StandaloneHeader';
import SendingFrom from './components/SendingFrom';
import PickupSection from './components/PickupSection';
import PickupError from './components/PickupError';
import PickupOutsideUsaModal from './components/PickupOutsideUsaModal';

import {maxContainerWidth} from 'styles/shared';

const StyledPageContainer = styled.div`
  width: ${maxContainerWidth};
  overflow-x: hidden;
`;

const StyledRow = styled(Row)`
  margin-bottom: 5rem;
`;

const ORIGIN = {
  companyName: '',
  contactName: '',
  country: '',
  phoneNumber: '',
  phoneNumberExtension: '',
  email: '',
  address: {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    postalCode: '',
    division: '',
    suburb: ''
  },
  isResidential: false
};

const PICKUP = {
  date: new Date(),
  startTime: formatHelper.getDefaultMinSliderTime(),
  endTime: SLIDER.INITIAL_END_TIME,
  location: '',
  notes: '',
  carrier: '',
  carrierProduct: ''
};

function SchedulePickupPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  const countryOptions: CountryOption[] = useSelector((state: AppState) => state.shipmentOptions.countryOptions);

  const [standalonePickup, setStandalonePickup] = useState<StandalonePickup>({
    origin: {...ORIGIN},
    pickup: {...PICKUP}
  });

  const [pickupOutsideUsaModalVisible, setPickupOutsideUsaModalVisible] = useState(false);
  const [errorPopupVisible, setErrorPopupVisible] = useState(false);
  const [pickupError, setPickupError] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorsScroll, setErrorsScroll] = useState(false);

  useEffect(() => {
    if (!validateOnChange) return;

    validateData();
  }, [standalonePickup, validateOnChange]);

    //scroll to the first issue that is not locally validated
    useEffect(() => {
      if (!errorsScroll) return;

      setErrorsScroll(false);

      // Ignore mobile elements if in desktop mode and vice versa
      const errors = $('.validation-error').filter(function() {
        return this.clientHeight !== 0;
      });

      let scrollTop = errors?.offset()?.top;

      if (scrollTop) {
        scrollTop = scrollTop - 250;
        $('html, body').animate({scrollTop}, 1000);
      }
    }, [errorsScroll]);

  function onChangeOrigin(field, value) {
    let updatedOrigin = {...standalonePickup.origin, [field]: value};

    if (field === 'country' && value !== COUNTRY_CODE.USA) {
      setPickupOutsideUsaModalVisible(true);
    }

    setStandalonePickup({...standalonePickup, origin: updatedOrigin});
  }

  function onChangePickup(field, value) {
    let updatedPickup;

    if (field === 'carrier') {
      updatedPickup = {...standalonePickup.pickup,
        [field]: value === CARRIER.FED_EX_EXPRESS_PICKUP || value === CARRIER.FED_EX_GROUND_PICKUP ? CARRIER.FED_EX : value,
        carrierProduct: value === CARRIER.UPS ? CARRIER.UPS_GROUND : value === CARRIER.DHL ? 'P' : value};

    } else {
      updatedPickup = {...standalonePickup.pickup, [field]: value};
    }

    setStandalonePickup({...standalonePickup, pickup: updatedPickup});
  }

  function isAllRequiredFieldEntered() {
    const origin = standalonePickup.origin;
    const pickup = standalonePickup.pickup;

    if (
      !origin.companyName ||
      !origin.contactName ||
      !origin.email ||
      !origin.phoneNumber ||
      !origin.address.addressLine1
    ) {
      return false;
    }

    if (!pickup.location || !pickup.carrier) return false;

    return true;
  }

  function validateData(): boolean {
    const validationResult: ValidationResult = validationHelper.validateStandalonePickup(
      standalonePickup,
      countryOptions
    );

    setErrors(validationResult.errors);

    return validationResult.valid;
  }

  async function confirmPickup() {
    if (!validateData()) {
      setErrorsScroll(true);
      setValidateOnChange(true);
      return;
    }

    const response: any = await dispatch(pickupActions.scheduleStandalonePickup(standalonePickup));
    const result = response as PickupSchedulingResult;

    setValidateOnChange(false);

    if (result?.successful && isEmpty(result.errors)) {
      pickupSuccessfullExecution();
    } else {
      const message = result?.errors?.[0]?.message || '';
      setErrorPopupVisible(true);
      setPickupError(message);
    }
  }

  function pickupSuccessfullExecution() {
    setIsConfirmed(true);

    const dateDisplay = dateHelper.displayShortDate(standalonePickup.pickup.date);
    const startTimeDisplay = formatHelper.displayTimeInAmPmFormat(standalonePickup.pickup.startTime);
    const endTimeDisplay = formatHelper.displayTimeInAmPmFormat(standalonePickup.pickup.endTime);

    dispatch(
      commonActions.infoAction({
        text: (
          <div>
            <span>You have successfully created pickup for</span>
            <br />
            <span>
              {dateDisplay}, {startTimeDisplay} - {endTimeDisplay}
            </span>
          </div>
        ),
        type: 'issue',
        actionTitle: 'Show Me Pickup',
        closeTitle: 'Close & Create New',
        action: () => {
          let link = navigationHelper.getViewHistoryPageLink(SECTION.STATUS.PICKUPS);
          history.push(link);
        },
        close: () => {
          setIsConfirmed(false);
          setStandalonePickup({origin: {...ORIGIN}, pickup: {...PICKUP}});
        }
      })
    );
  }

  function cancelPickupError() {
    setStandalonePickup({origin: {...ORIGIN}, pickup: {...PICKUP}});
    setErrorPopupVisible(false);
    setErrors({});
    setPickupError('');
  }

  function tryConfirmPickupWithError() {
    setErrorPopupVisible(false);
    setPickupError('');
  }

  function render() {
    let isButtonActive = isAllRequiredFieldEntered();

    const description = (
      <span>
        <strong>DHL pickups for OptimalShip clients are free!</strong>
        <br />
        <strong>UPS</strong> pickups are <strong>$8.50 per shipment</strong>
      </span>
    );

    return (
      <StyledPageContainer>
        <StandaloneHeader
          title="Scheduling Pickups"
          subtitle="If you already have shipping documents prepared and just need to schedule a pickup, you can do it with our Standalone Pickup Scheduler!"
          description={description}
          image="delivery-box.png"
        />

        <StyledRow>
          <Col lg={6}>
            <SendingFrom origin={standalonePickup.origin} errors={errors} onChange={onChangeOrigin} />
          </Col>

          <Col lg={6}>
            <PickupSection
              pickup={standalonePickup.pickup}
              errors={errors}
              onChange={onChangePickup}
              isButtonActive={isButtonActive}
              isConfirmed={isConfirmed}
              onConfirm={confirmPickup}
            />
          </Col>
        </StyledRow>

        {errorPopupVisible && (
          <PickupError
            visible={errorPopupVisible}
            message={pickupError}
            onCancel={cancelPickupError}
            onTry={tryConfirmPickupWithError}
          />
        )}

        {pickupOutsideUsaModalVisible && (
          <PickupOutsideUsaModal
            visible={pickupOutsideUsaModalVisible}
            close={() => setPickupOutsideUsaModalVisible(false)}
          />
        )}
      </StyledPageContainer>
    );
  }

  return render();
}

export default SchedulePickupPage;
