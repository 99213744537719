import React from 'react';

import PAYMENT_METHOD from 'constants/paymentMethods';

import SelectInput from 'components/common/SelectInput/SelectInput';

interface Props {
  paymentMethod: string;
  onChange: OnChangeHandler;
}

function PaymentMethodSelection({paymentMethod, onChange}: Props) {
  const paymentOptions: BasicOption[] = [
    {value: PAYMENT_METHOD.CREDIT_CARD, label: 'Credit/Debit Card'},
    {value: PAYMENT_METHOD.ACH, label: 'ACH/Bank Account'}
  ];

  return (
    <SelectInput
      name="paymentMethod"
      label="Payment Method"
      value={paymentMethod}
      options={paymentOptions}
      onChange={onChange}
    />
  );
}

export default PaymentMethodSelection;
