import {css} from 'styled-components/macro';
import {rgba} from 'polished';

export const colors = {
  darkBlue_dark: '#252733',
  darkBlue_main: '#363740',
  darkBlue_light: '#A4A6B3',
  darkBlue_lighter: '#DFE0EB',
  blue_main: '#DDE2FF',
  grey_main: '#C4B9B6',
  grey_light: '#F8F8F8',
  grey_lighter: '#f7f8fc',
  grey_concrete: '#F3F3F3',
  orange_dark: '#EF653C',
  orange_main: '#FC7D58',
  orange_light: '#F18B6E',
  orange_lighter: '#ffdfd6',
  bg_orange_main: '#FFEAE4',
  bg_orange_hover: '#FAD6CC',
  bg_orange_selected: '#FFD4C8',
  bg_orange_error: '#FFECEC',
  bg_orange_accent: '#fff2ee',
  green_main: '#38C976',
  green_light: '#DFFAEB',
  green_lighter: '#F5FFF9',
  red_main: '#E33636',
  red_light: '#FFDBDB',
  red_lighter: '#FFF1F1',
  red_selected: '#ffd1d1',
  white: '#FFFFFF',
  black: '#000000'
};

export const navBarHeight = '10rem';
export const leftPanelWidth = '25.5rem';
export const houseAccountMessageHeight = '4.2rem';
export const ordersNavBarHeight = '5rem';
export const mainContainerPadding = '2.6rem';
export const helpWidgetHeight = '6.8rem';

export const minInputContainerHeight = '10.5rem';
export const defaultInputHeight = '4.8rem';

export const maxContainerWidth = '144rem';

export const maxUsersContainerWidth = '112.2rem';

export const mainBorder = `0.1rem solid ${colors.darkBlue_lighter}`;

export const opacity = (opacity, color = colors.black) => rgba(color, opacity);

export const boxShadow = `0 0.4rem 2.4rem ${opacity(0.25)}`;

export const buttonSideMargin = '1.6rem';
export const buttonLeftRightMargin = `0 ${buttonSideMargin}`;
export const buttonLeftMargin = `0 0 0 ${buttonSideMargin}`;
export const buttonRightMargin = `0 ${buttonSideMargin} 0 0`;

export const mainFont = css`
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: 0.03rem;
`;

export const secondaryFont = css`
  font-size: 1.4rem;
  line-height: 1.9rem;
  letter-spacing: 0.03rem;
`;

export const middleFont = css`
  font-size: 1.8rem;
  line-height: 2.5rem;
  letter-spacing: 0.03rem;
`;

export const largeFont = css`
  font-size: 2rem;
  line-height: 2.7rem;
  letter-spacing: 0.03rem;
`;

export const authFont = css`
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: -0.011em;
`;

export const headerFont = css`
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 3.3rem;
  letter-spacing: 0.03rem;
`;

export const pageTitle = css`
  font-weight: bold;
  font-size: 2.6rem;
  line-height: 3.5rem;
  letter-spacing: 0.03rem;
  text-transform: capitalize;
  margin: 3rem 0;
`;

export const pageSubtitle = css`
  font-size: 1.8rem;
  line-height: 2.5rem;
  letter-spacing: 0.03rem;
  max-width: 53rem;
  text-align: center;
`;

export const degree = css`
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.03rem;
  margin-left: 0.3rem;
  color: ${colors.orange_main};
`;

export const leftPanel = css`
  width: ${leftPanelWidth};
  height: 100%;
  position: fixed;
  z-index: 1;
  top: ${navBarHeight};
  left: 0;
  overflow-x: hidden;
  background: ${colors.darkBlue_main};
`;

export const scrollStyles = css`
  ::-webkit-scrollbar {
    width: 0.4rem;
    background: ${colors.darkBlue_lighter};
    border-radius: 0.4rem;
  }

  ::-webkit-scrollbar-thumb {
    min-height: 2.4rem;
    background: ${colors.orange_main};
    border-radius: 0.4rem;
  }
`;

const desktopSize = 1075;

export const mediaQueries = {
  mobileMax: 'screen and (max-width: 767px)',
  tabletMin: 'screen and (min-width: 768px)',
  laptopMin: 'screen and (min-width: 992px)',
  desktopMin: `screen and (min-width: ${desktopSize}px)`,
  desktopMax: `screen and (max-width: ${desktopSize}px)`,
  desktopLargeMax: 'screen and (max-width: 1400px)'
};

export const errorInfoTitle = css`
  font-weight: 900;
  font-size: 3.1rem;
  line-height: 4.2rem;
  letter-spacing: 0.03rem;
`;
