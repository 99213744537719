import React from 'react';
import styled from 'styled-components/macro';
import {get} from 'lodash';

import CARRIER from 'constants/carriers';

import pickupService from 'services/pickupService';

import Container from 'components/common/Container';
import FormHeader from 'components/common/FormHeader';
import Button, {ButtonType, ButtonTheme} from 'components/common/Button';
import Calendar from 'components/common/Scheduler/Calendar';
import Slider from 'components/common/Scheduler/Slider';
import RadioInput from 'components/common/RadioInput/RadioInput';
import TextAreaInput from 'components/common/TextAreaInput';
import Flex from 'components/common/Flex';
import CalendarTooltip from 'components/tooltips/CalendarTooltip';
import ReactSelectInput from 'components/common/SelectInput/ReactSelectInput';

const StyledContainer = styled(Container)`
  height: 100%;
`;

const StyledButtonContainer = styled(Flex)`
  margin-top: 5rem;
`;

interface Props {
  pickup: StandalonePickupData;
  errors: object;
  isButtonActive: boolean;
  onChange: (field: string, value: any) => void;
  onConfirm: () => void;
  isConfirmed: boolean;
}

function PickupSection({pickup, errors, isButtonActive, onChange, onConfirm, isConfirmed}: Props) {
  const carrierOptions = [
    {
      value: CARRIER.DHL,
      label: (
        <>
          <span>DHL</span> - International Shipping
        </>
      )
    },
    {
      value: CARRIER.UPS,
      label: (
        <>
          <span>UPS</span> - Domestic Shipping
        </>
      )
    },
  ];

  function onTimeChange(field, value: number[]) {
    if (pickup.startTime !== value[0]) {
      onChange('startTime', value[0]);
    }

    if (pickup.endTime !== value[1]) {
      onChange('endTime', value[1]);
    }
  }

  function render() {
    const buttonType: ButtonType = isConfirmed ? 'primary' : 'secondary';
    const buttonTheme: ButtonTheme = isConfirmed ? 'success' : 'main';
    const buttonText = isConfirmed ? 'Pickup Confirmed' : 'Confirm Pickup';

    return (
      <StyledContainer>
        <FormHeader>When would you like to ship it?</FormHeader>

        <ReactSelectInput
          name="carrier"
          label="Select Service"
          required={true}
          options={carrierOptions}
          value={pickup.carrier}
          placeholder="eg. Domestic"
          onChange={onChange}
          error={get(errors, 'pickup.carrier', '')}
        />

        <Calendar
          name="date"
          label="Select Shipment date"
          tooltip={CalendarTooltip()}
          confirmedDates={[]}
          value={pickup.date}
          onChange={onChange}
        />

        <br />

        <Slider
          name="time"
          label="Pickup Time:"
          value={[pickup.startTime, pickup.endTime]}
          carrier={pickup.carrier}
          onChange={onTimeChange}
        />

        <RadioInput
          name="location"
          label="Location of pickup"
          required={true}
          value={pickup.location}
          options={pickupService.getPickupLocationOptions()}
          onChange={onChange}
          error={get(errors, 'pickup.location', '')}
        />

        <TextAreaInput
          name="notes"
          label="Write down note for driver"
          placeholder="Short description"
          value={pickup.notes}
          onChange={onChange}
          error={get(errors, 'pickup.notes', '')}
        />

        <StyledButtonContainer justify="flex-end">
          <Button type={buttonType} theme={buttonTheme} disabled={!isButtonActive} onClick={onConfirm}>
            {buttonText}
          </Button>
        </StyledButtonContainer>
      </StyledContainer>
    );
  }

  return render();
}

export default PickupSection;
