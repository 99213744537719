import WHO_PAYS from 'constants/payment/whoPays';
import COMMON from 'constants/common';

import httpHelper from 'helpers/httpHelper';
import draftExtras from 'domain/entities/draftExtras';

import mapper from './mapping';
import entities from 'domain/entities';

export default {
  getShipments,
  getShipmentById,
  createShipment,
  rateShipment,
  getShipmentDocument,
  cancelShipment
};

async function getShipments(
  activePage: number,
  pageSize: number
): Promise<{shipments: Shipment[]; totalItems: number}> {
  const options = {
    page: activePage,
    itemsPerPage: pageSize
  };

  const result: {items: ShipmentResponse[]; totalItems: number} = await httpHelper.get('/shipments', options, {
    noCache: true,
    totalItems: true
  });

  return {
    shipments: result.items.map(response => mapShipment(response)),
    totalItems: result.totalItems
  };
}

async function getShipmentById(id): Promise<Shipment> {
  const result: ShipmentResponse = await httpHelper.get(`/shipments/${id}`, {});
  return mapShipment(result);
}

function createShipment(data: PrepareShipmentDto): Promise<PrepareShipmentResponse> {
  const shipmentRequest: ShipmentRequest = mapper.shipment.mapShipmentRequestFromDraft(
    data.id,
    data.draft,
    data.eeiFilingTypeOptions,
    data.paymentId,
    data.paymentTime
  );
  return httpHelper.post('/prepare_shipment_actions', shipmentRequest, {useHouseAccount: true});
}

function getShipmentDocument(documentId): Promise<ShipmentDocument> {
  return httpHelper.get(`/shipment_documents/${documentId}`, {});
}

async function rateShipment(data: RateShipmentDto): Promise<{rates: Rate[]; errorMessage: string}> {
  const draft = data.draft;
  const sendingFrom: Sending = draft.shipmentFrom;
  const sendingTo: Sending = draft.shipmentTo;
  const pickupDelivery: PickupDelivery = draft.pickupDelivery;
  const products: Products = draft.shipmentDetails.products;
  const additionalServices = draft.additionalServices;

  const shipmentDate = pickupDelivery?.pickup?.date || pickupDelivery?.shipmentDate;

  const dutiesAndTaxesPayment = draft.payment.dutiesAndTaxesPayment;

  let hasDutiesAndTaxesPaidByCarrierAccount = false;
  if (draft.isExport && dutiesAndTaxesPayment === WHO_PAYS.MY_ACCOUNT) {
    hasDutiesAndTaxesPaidByCarrierAccount = true;
  }

  const body: RateShipmentRequest = {
    shipmentId: data.shipmentId,
    accountNumber: '', //omit to have it autopopulated by client data
    originCountryCode: sendingFrom.country,
    originZip: sendingFrom.address.postalCode,
    destinationCountryCode: sendingTo.country,
    destinationCity: sendingTo.address.city,
    destinationZip: sendingTo.address.postalCode,
    targetShipDate: shipmentDate,
    pieces: mapper.shipment.mapShipmentRequestPieces(draft),
    currency: draft.currency,
    isResidential: draft.shipmentTo.isResidential,
    isResidentialPickup: draft.shipmentFrom.isResidential,
    hasNewPickupRequest: draftExtras.hasNewPickupRequest(draft),
    hasEeiFiledByCarrier: products.isItnNumberExists === false ? true : false,
    hasNeutralDelivery: additionalServices.isNeutralDeliveryServiceRequired ? true : false,
    hasDutiesAndTaxesPaidByCarrierAccount,
    markInactive: false
  };

  if (additionalServices.doYouNeedInsurance) {
    const insuranceValue = draft.isDocumentsShipment
      ? COMMON.DOCUMENT_INSURANCE_VALUE
      : additionalServices.insuranceValue;
    body.insuredValue = mapper.shipment.mapCurrency(insuranceValue, draft.currency);
  }

  if (draft.isDomestic) {
    body.insuredValue = mapper.shipment.mapCurrency(draft.declaredTotalValue, draft.currency);
    //Signature Required
    body.isSignatureRequired = additionalServices.signature === '2';
    //Adult Signature Required
    body.isAdultSignatureRequired = additionalServices.signature === '3';
  }

  const response: RateShipmentResponse = await httpHelper.post('/rate_shipment_actions', body, {
    useHouseAccount: true
  });

  let rates: Rate[] = [];

  if (response?.rates) {
    response.rates.forEach(rate => {
      rates.push(entities.rate.createFromData(rate, response.createdInId));
    });
  }

  let errorMessage = '';
  if (response?.success === false && response?.error) {
    errorMessage = response.error;
  } else if (response['hydra:description']) {
    errorMessage = response['hydra:description'];
  }

  return {rates, errorMessage};
}

async function cancelShipment(id: string, hasAssociatedPickup: boolean): Promise<CancelShipmentResponse> {
  const body = {
    shipmentId: id,
    cancelRelatedPickup: hasAssociatedPickup
  };

  return httpHelper.post('/cancel_shipment_actions', body, {useHouseAccount: true});
}

//helper methods

function mapShipment(shipmentResponse) {
  let shipment = mapper.shipment.mapShipmentFromShipmentResponse(shipmentResponse);
  return shipment;
}
