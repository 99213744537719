import ELECTRONIC_EXPORT_FILING from 'constants/electronicExportFilings';
import INVOICE_TYPE from 'constants/invoiceTypes';
import PACKAGING_TYPE from 'constants/packagingTypes/packagingTypes';
import COMMON from 'constants/common';
import PAYMENT_TIME from 'constants/paymentTime';
import FEATURE from 'constants/features';
import WHO_PAYS from 'constants/payment/whoPays';
import MEASUREMENT_SYSTEM from 'constants/measurementSystems';
import UNIT from 'constants/units';

import unitHelper from 'helpers/unitHelper';

import shipmentDataMapper from './shipmentData';

export default {
  mapShipmentFromShipmentResponse,
  mapShipmentRequestFromDraft,
  mapShipmentRequestPieces,
  mapShipmentRequestOrigin,
  mapCurrency
};

function mapShipmentFromShipmentResponse(shipmentResponse: ShipmentResponse): Shipment {
  let shipmentData = shipmentDataMapper.mapShipmentDataFromShippingResponse(shipmentResponse);

  return {
    ...shipmentData,
    id: shipmentResponse.id,
    hasError: shipmentResponse.hasError,
    targetShipDate: shipmentResponse.targetShipDate,
    trackingNumber: shipmentResponse.trackingNumber,
    carrier: {
      id: shipmentResponse?.carrierService?.id,
      name: shipmentResponse?.carrierService?.name,
      productCode: shipmentResponse?.carrierService?.productCode,
      carrier: shipmentResponse?.carrierService?.carrier || shipmentResponse?.carrier
    },
    primaryStatusFlag: shipmentResponse.primaryStatusFlag,
    primaryStatusFlagLabel: shipmentResponse.primaryStatusFlagLabel,
    documents: shipmentResponse.documents,
    ownedById: shipmentResponse.ownedById
  };
}

function mapShipmentRequestFromDraft(
  id: string,
  draft: Draft,
  eeiFilingTypeOptions: Array<string>,
  paymentId: string,
  paymentTime: string
): ShipmentRequest {
  const shipmentFrom: Sending = draft.shipmentFrom;
  const shipmentTo: Sending = draft.shipmentTo;
  const payment: Payment = draft.payment;
  const currency = draft.currency;

  const toAddress: Address = shipmentTo.address;

  const documents = draft.shipmentDetails.documents;
  const products: Products = draft.shipmentDetails.products;
  const isCustomsInvoiceRequired = draft.isDutiable;

  const documentTab = draft.isDocumentsShipment;
  const predefinedPaymentFields = FEATURE.PAYMENT_AUTH && draft.isDutiable && paymentTime === PAYMENT_TIME.PAY_NOW;

  let isDomestic = draft.isDomestic;

  let shipmentRequest: ShipmentRequest = {
    shipmentId: id,
    origin: mapShipmentRequestOrigin(shipmentFrom),
    destination: {
      country: shipmentTo.country,
      companyName: shipmentTo.companyName,
      address: toAddress.addressLine1,
      postalCode: toAddress.postalCode,
      city: toAddress.city,
      division: toAddress.division,
      dependentLocality: toAddress.suburb,
      phoneNumber: shipmentTo.phoneNumber,
      phoneNumberExtension: shipmentTo.phoneNumberExtension,
      email: shipmentTo.email,
      contactName: shipmentTo.contactName,
      addressLine2: toAddress.addressLine2,
      addressLine3: toAddress.addressLine3,
      isResidential: shipmentTo.isResidential
    },
    pieces: mapShipmentRequestPieces(draft),
    whoPaysShipping: predefinedPaymentFields ? WHO_PAYS.MY_ACCOUNT : payment.freightPayment,
    billingAccountNumber: '', //omit this field; it will auto-fill the client's account number
    billingAccountNumberPostalCode: predefinedPaymentFields ? '' : payment.billingAccountNumberPostalCode,
    shipperAccountNumber: predefinedPaymentFields ? '' : payment.shipperAccountNumber,
    targetShipDate: draft.pickupDelivery.pickup ? draft.pickupDelivery.pickup.date : draft.pickupDelivery.shipmentDate,
    description: draft.description,
    shipper: mapAddressee(shipmentFrom, isDomestic),
    receiver: mapAddressee(shipmentTo, isDomestic),
    externalSourceReference: draft.shipmentReferenceNumber,
    receiverReference: products.receiverReference,
    carrierProduct: draft.pickupDelivery.carrierProduct,
    additionalServices: [],
    hasBatteries: draft.hasBatteries,
    hasDangerousGoods: draft.hasDangerousGoods,
    payWith: paymentId
  };

  if (draft.additionalServices.doYouNeedInsurance) {
    const insuranceValue = draft.isDocumentsShipment
      ? COMMON.DOCUMENT_INSURANCE_VALUE
      : draft.additionalServices.insuranceValue;
    shipmentRequest.insuredValue = mapCurrency(insuranceValue, currency);
  }

  if (draft.isDomestic) {
    shipmentRequest.insuredValue = mapCurrency(draft.declaredTotalValue, currency);
  }

  if (draft.additionalServices.signature) {
    shipmentRequest.additionalServices.push({id: draft.additionalServices.signature});
  }

  if (draft.isDutiable) {
    const invoiceTypeValue = draft.isProformaInvoiceType ? products.purposeOfShipment : products.invoiceType;
    const invoiceType: any = invoiceTypeValue ? invoiceTypeValue : INVOICE_TYPE.EXTERNAL;
    const declaredShipmentValue = documentTab ? documents.declaredShipmentValue : products.declaredShipmentValue;

    shipmentRequest.international = {
      dutyAccountNumber: predefinedPaymentFields ? '' : payment.dutyAccountNumber,
      whoPaysDuties: predefinedPaymentFields ? WHO_PAYS.RECEIVER : payment.dutiesAndTaxesPayment,
      declaredValue: mapCurrency(declaredShipmentValue, currency),
      termsOfTrade: {
        code: predefinedPaymentFields ? WHO_PAYS.DAP : payment.customsTermsOfTrade
      },
      exportLineItems: mapExportLineItems(products.items, documentTab, draft),
      eeiFiling: mapEeiFillingObject(products, products.isItnNumberExists, eeiFilingTypeOptions, draft.isExport),
      invoiceType,
      invoiceNumber: products.invoiceNumber,
      packageMarks: products.packageMarks,
      packageRemarks1: products.otherRemarks,
      freightInvoiceCharge: mapCurrency(products.freightCost, currency),
      insuranceInvoiceCharge: mapCurrency(products.insuranceCost, currency),
      miscInvoiceCharge: mapCurrency(products.miscCost, currency)
    };

    if (isCustomsInvoiceRequired) {
      shipmentRequest.international.exportType = products.exportType;
    }
  }

  return shipmentRequest;
}

function mapShipmentRequestPieces(draft: Draft) {
  const shipmentDetails = draft.shipmentDetails;
  const measurementSystem = draft.measurementSystem;

  const lengthUnit: string = unitHelper.getUnitFromMeasurementSystem(measurementSystem);
  const weightUnit: string = unitHelper.getUnitFromMeasurementSystem(measurementSystem, true);

  let pieces: any = [];

  if (draft.isDocumentsShipment) {
    const documents = shipmentDetails.documents;

    let weightValue = documents.documentWeight;
    if (!documents.isDocumentWeightAboveHalfPound) weightValue = 0.4;

    pieces.push({
      weight: {unit: weightUnit, value: weightValue},
      length: {unit: lengthUnit, value: 1}, //Fix-Mapping:
      width: {unit: lengthUnit, value: 1}, //Fix-Mapping:
      depth: {unit: lengthUnit, value: 1}, //Fix-Mapping:
      quantity: documents.numberOfPackages,
      packagingType: documents.typeOfPackaging,
      description: documents.generalDescription,
      documentDeclaredValue: mapCurrency(documents.declaredShipmentValue, draft.currency),
      isDocument: true
    });
  } else {
    shipmentDetails.products.packages.forEach((packageItem: Package) => {
      let piece: any = {
        weight: {
          unit: weightUnit,
          value: packageItem.weight
        },
        length: {
          unit: lengthUnit,
          value: packageItem.length
        },
        width: {
          unit: lengthUnit,
          value: packageItem.width
        },
        depth: {
          unit: lengthUnit,
          value: packageItem.height
        },
        quantity: packageItem.numberOfPackages,
        packagingType: PACKAGING_TYPE.CUSTOMER_PROVIDED, //Fix-Mapping:
        isDocument: false
      };

      if (draft.isDomestic) {
        piece.documentDeclaredValue = mapCurrency(packageItem.declaredValue, draft.currency);
      }

      pieces.push(piece);
    });
  }

  return pieces;
}

function mapCurrency(value, currency): Currency {
  return {amount: value, currency};
}

//helper methods

function mapExportLineItems(items, isDocumentTab, draft: Draft) {
  if (isDocumentTab) return null;

  if (!draft.isInvoiceItemsRequired) return [];

  let exportLineItems: any = [];

  items.forEach((invoiceItem: ProductItem) => {
    let license: any = {};

    if (draft.isLicenseRequired) {
      license.number = invoiceItem.exportLicenseNumber;

      if (invoiceItem.isLicenseDetailsRequired) {
        license.symbol = invoiceItem.licenseSymbol;
        license.expiry = invoiceItem.exportLicenseExpiryDate;
      }
    } else {
      license = null;
    }

    let exportLineItem: any = {
      description: invoiceItem.itemDescription,
      quantity: {
        amount: invoiceItem.quantity,
        unit: invoiceItem.units
      },
      countryOfOrigin: invoiceItem.itemMade,
      commodityCode: invoiceItem.commodityCode,
      value: {
        currency: draft.currency,
        amount: invoiceItem.itemValue
      },
      eccn: invoiceItem.isEccnRequired ? invoiceItem.eccnValue : '',
      license,
      weight: {
        value: invoiceItem.weight ?? 0,
        unit: draft.measurementSystem === MEASUREMENT_SYSTEM.IMPERIAL ? UNIT.MASS_POUND : UNIT.MASS_KG
      }
    };

    exportLineItems.push(exportLineItem);
  });

  return exportLineItems;
}

function mapShipmentRequestOrigin(sendingFrom: Sending | StandaloneOrigin) {
  const fromAddress: Address = sendingFrom.address;

  let origin = {
    companyName: sendingFrom.companyName,
    address: fromAddress.addressLine1,
    city: fromAddress.city,
    division: fromAddress.division,
    postalCode: fromAddress.postalCode,
    dependentLocality: fromAddress.suburb,
    phoneNumber: sendingFrom.phoneNumber,
    phoneNumberExtension: sendingFrom.phoneNumberExtension,
    country: sendingFrom.country,
    email: sendingFrom.email,
    contactName: sendingFrom.contactName,
    addressLine2: fromAddress.addressLine2,
    addressLine3: fromAddress.addressLine3,
    isResidential: sendingFrom.isResidential
  };

  return origin;
}

function mapAddressee(sending: Sending, isDomestic: boolean) {
  let vatNumber = sending.VATNumber || null;
  let taxId = sending.taxId || null;
  let eoriNumber = sending.EORINumber || null;

  if (isDomestic) {
    vatNumber = null;
    taxId = null;
    eoriNumber = null;
  }

  return {
    name: sending.contactName,
    vatNumber,
    taxId,
    eoriNumber
  };
}

function mapEeiFillingObject(products, isItnNumberExists, eeiFilingTypeOptions, isUSAExport) {
  if (!isUSAExport) return null;

  let type = '';
  let exemption = '';
  let itn = '';
  let ein = '';

  const exemptedType = eeiFilingTypeOptions[0];

  switch (products.electronicExportFiling) {
    case ELECTRONIC_EXPORT_FILING.EXEMPTED:
      type = exemptedType;
      exemption = ELECTRONIC_EXPORT_FILING.DEFAULT_EXEMPTION;
      break;

    case ELECTRONIC_EXPORT_FILING.OTHER:
      type = exemptedType;
      exemption = products.foreignTradeRegulations;
      break;

    case ELECTRONIC_EXPORT_FILING.NO:
      if (isItnNumberExists) {
        type = eeiFilingTypeOptions[1];
        itn = products.ITNNumber;
      } else {
        type = eeiFilingTypeOptions[2];
        ein = products.EINNumber;
      }
      break;

    default:
      return null;
  }

  return {
    type,
    exemption,
    itn,
    ein
  };
}
