import {isNumber} from 'lodash';

import COMMON from 'constants/common';
import UNIT from 'constants/units';
import CARRIER from 'constants/carriers';
import PACKAGING_TYPE from 'constants/packagingTypes/packagingTypes';

import dateHelper from 'helpers/dateHelper';
import config from 'config';
import httpHelper from 'helpers/httpHelper';
import formatHelper from 'helpers/formatHelper';

import mapper from './mapping';
import entities from 'domain/entities';

export default {
  getPickups,
  schedulePickup,
  scheduleStandalonePickup,
  schedulePickupForShipment,
  editPickup,
  cancelPickup,
  addToPickup,
  checkPickupCapability,
  validatePickup,
  getConfirmPickupRequest,
  getAddShipmentToPickupRequest
};

async function getPickups(
  activePage: number,
  pageSize: number,
  address = '',
  carrier = ''
): Promise<{pickups: Pickup[]; totalItems: number}> {
  const today = new Date(new Date().setHours(0,0,0,0));
  const pastDate = new Date(today);
  pastDate.setDate(pastDate.getDate() - COMMON.PICKUP_HISTORY_DAYS);

  const readyAt = address ? today : pastDate;

  const options: any = {
    page: activePage,
    itemsPerPage: pageSize,
    'readyAt[after]': dateHelper.displayDate(readyAt, config.format.apiFormat),
    canceled: false
  };

  if (address) options.address = address;
  if (carrier) options.carrier = carrier;

  const result: PickupsResponse = await httpHelper.get('/pickups', options, {noCache: true, totalItems: true});

  return {
    pickups: result.items.map(response => mapPickup(response)),
    totalItems: result.totalItems
  };
}

function schedulePickup(data: ConfirmPickupDto): Promise<PickupSchedulingResult> | null {
  const body = getConfirmPickupRequest(data);

  if (body === null) return null;

  return httpHelper.post('/schedule_pickup_actions', body, {useHouseAccount: true});
}

function scheduleStandalonePickup(data: StandalonePickup): Promise<PickupSchedulingResult> {
  const pickup = data.pickup;

  const body: ConfirmPickupRequest = {
    origin: mapper.shipment.mapShipmentRequestOrigin(data.origin),
    pieces: [
      {
        weight: {unit: UNIT.MASS_POUND, value: 1},
        length: {unit: UNIT.LENGTH_INCH, value: 1},
        width: {unit: UNIT.LENGTH_INCH, value: 1},
        depth: {unit: UNIT.LENGTH_INCH, value: 1},
        description: '',
        quantity: 1,
        packagingType: PACKAGING_TYPE.CUSTOMER_PROVIDED,
        isDocument: false
      }
    ], //Fix-Mapping:
    carrier: pickup.carrier,
    carrierProduct: pickup.carrierProduct,
    location: pickup.location,
    readyAtDate: dateHelper.displayDate(pickup.date, config.format.datePicker),
    readyAtTime: entities.pickup.getPickupTime(pickup.startTime),
    closesAtTime: entities.pickup.getPickupTime(pickup.endTime),
    pickupNotes: pickup.notes,
    shipmentId: null
  };

  return httpHelper.post('/schedule_pickup_actions', body, {useHouseAccount: true});
}

function schedulePickupForShipment(pickup: ConfirmPickupForShipmentDto): Promise<PickupSchedulingResult> {
  const body: ConfirmPickupForShipmentRequest = {
    location: pickup.location,
    readyAtDate: dateHelper.displayDate(pickup.date, config.format.datePicker),
    readyAtTime: entities.pickup.getPickupTime(pickup.startTime),
    closesAtTime: entities.pickup.getPickupTime(pickup.endTime),
    pickupNotes: pickup.notes,
    shipmentId: pickup.shipmentId
  };

  return httpHelper.post('/schedule_pickup_for_shipment_actions', body, {useHouseAccount: true});
}

function editPickup(pickup: PickupData, pickupId: string): Promise<PickupSchedulingResult> {
  const body: EditPickupRequest = {
    pickup: getIri(pickupId, 'pickups'),
    location: pickup.location,
    pickupNotes: pickup.notes,
    readyAtDate: dateHelper.displayDate(pickup.date, config.format.datePicker),
    readyAtTime: entities.pickup.getPickupTime(pickup.startTime),
    closesAtTime: entities.pickup.getPickupTime(pickup.endTime)
  };

  return httpHelper.post('/modify_pickup_actions', body, {useHouseAccount: true});
}

function cancelPickup(pickupId: string): Promise<PickupSchedulingResult> {
  const body = {
    pickup: getIri(pickupId, 'pickups')
  };

  return httpHelper.post('/cancel_pickup_actions', body);
}

function addToPickup(data: AddShipmentToPickupDto): Promise<SuccessfullResponse> | null {
  const body = getAddShipmentToPickupRequest(data);

  if (!body) return null;

  return httpHelper.post('/add_to_pickup_actions', body);
}

async function checkPickupCapability(data: CheckPickupCapabilityDto): Promise<PickupCapability> {
  const draft = data.draft;

  const sendingTo = draft.shipmentTo;
  const documents = draft.shipmentDetails.documents;
  const products: Products = draft.shipmentDetails.products;

  const toAddress: Address = sendingTo.address;
  const shipmentDate = draft.pickupDelivery.pickup
    ? draft.pickupDelivery.pickup.date
    : draft.pickupDelivery.shipmentDate;

  const timeInterval = getCheckPickupTimeInterval(shipmentDate, data.carrier);

  let declaredValue = draft.isDocumentsShipment ? documents.declaredShipmentValue : products.declaredShipmentValue;
  if (draft.isDomestic) {
    declaredValue = draft.declaredTotalValue;
  }

  const body: CheckPickupCapabilityRequest = {
    origin: mapper.shipment.mapShipmentRequestOrigin(draft.shipmentFrom),
    destination: {
      companyName: sendingTo.companyName,
      address: toAddress.addressLine1,
      city: toAddress.city,
      division: toAddress.division,
      postalCode: toAddress.postalCode,
      dependentLocality: toAddress.suburb,
      phoneNumber: sendingTo.phoneNumber,
      country: sendingTo.country,
      email: sendingTo.email,
      contactName: sendingTo.contactName,
      addressLine2: toAddress.addressLine2,
      addressLine3: toAddress.addressLine3,
      isResidential: sendingTo.isResidential
    },
    pieces: mapper.shipment.mapShipmentRequestPieces(draft),
    carrier: getCarrier(draft, data?.carrier),
    carrierProduct: data?.carrierProduct ?? '',
    readyAtDate: dateHelper.displayDate(shipmentDate, config.format.datePicker),
    readyAtTime: timeInterval.readyAtTime,
    closesAtTime: timeInterval.closesAtTime,
    shipmentId: data.shipmentId,
    declaredValue: mapper.shipment.mapCurrency(declaredValue, draft.currency)
  };

  let response: PickupCapability = await httpHelper.post('/check_capabilities_actions', body, {useHouseAccount: true});

  return mapPickupCapability(response);
}

function validatePickup(data: ValidatePickupDto): Promise<ValidatePickupResponse> {
  const draft = data.draft;
  const pickupData = data.pickup;

  const body: ValidatePickupRequest = {
    origin: mapper.shipment.mapShipmentRequestOrigin(draft.shipmentFrom),
    pieces: mapper.shipment.mapShipmentRequestPieces(draft),
    carrier: getCarrier(draft, data?.carrier),
    carrierProduct: data?.carrierProduct ?? '',
    location: pickupData.location,
    readyAtDate: dateHelper.displayDate(pickupData.date, config.format.datePicker),
    readyAtTime: entities.pickup.getPickupTime(pickupData.startTime),
    closesAtTime: entities.pickup.getPickupTime(pickupData.endTime),
    pickupNotes: pickupData.notes,
    shipmentId: data.shipmentId
  };

  return httpHelper.post('/validate_pickup_actions', body, {useHouseAccount: true});
}

//helper methods

function getIri(id: string, urlPath: string) {
  return `/${urlPath}/${id}`;
}

function getCheckPickupTimeInterval(date, carrier) {
  const HOURS_TO_ADD = 2;
  const minTime = formatHelper.getDefaultMinSliderTime(carrier);
  let startTime = '';
  let endTime = '';

  const addLeadZero = val => {
    if (val < 10) val = `0${val}`;
    return val;
  };

  if (dateHelper.todayDate(date)) {
    let today = new Date();
    startTime = addLeadZero(today.getHours()) + ':' + addLeadZero(today.getMinutes()) + ':00';
    endTime = addLeadZero(today.getHours() + HOURS_TO_ADD) + ':' + addLeadZero(today.getMinutes()) + ':00';
  } else {
    startTime = entities.pickup.getPickupTime(minTime);
    endTime = entities.pickup.getPickupTime(minTime + HOURS_TO_ADD * 60);
  }

  return {
    readyAtTime: startTime,
    closesAtTime: endTime
  };
}

function mapPickup(pickupResponse) {
  let pickup = mapper.pickup.mapPickupFromPickupResponse(pickupResponse);
  return pickup;
}

function mapPickupCapability(pickupCapabilityResponse: PickupCapability): PickupCapability {
  let displayTime = date => {
    let time = formatHelper.convertTimeStringIntoNumber(date, undefined);

    if (!isNumber(time)) return COMMON.EMPTY_FIELD;

    return formatHelper.displayTimeInAmPmFormat(time, true);
  };

  return {
    bookByTime: displayTime(pickupCapabilityResponse.bookByTime),
    pickupWindowEarliestTime: displayTime(pickupCapabilityResponse.pickupWindowEarliestTime),
    pickupWindowLatestTime: displayTime(pickupCapabilityResponse.pickupWindowLatestTime),
    minimumPickupWindow: pickupCapabilityResponse.minimumPickupWindow,
    cutOffTime: displayTime(pickupCapabilityResponse.cutOffTime)
  };
}

function getCarrier(draft: Draft, carrier?: string): string {
  if (carrier) return carrier;

  //default carrier value
  return draft.isDomestic ? CARRIER.UPS : CARRIER.DHL;
}

function getConfirmPickupRequest(data: ConfirmPickupDto | null): ConfirmPickupRequest | null {
  if (!data) return null;

  const draft = data.draft;

  const pickup = draft.pickupDelivery?.pickup;
  if (!pickup) return null;

  const body: ConfirmPickupRequest = {
    origin: mapper.shipment.mapShipmentRequestOrigin(draft.shipmentFrom),
    pieces: mapper.shipment.mapShipmentRequestPieces(draft),
    carrier: getCarrier(draft, data?.carrier),
    carrierProduct: data?.carrierProduct ?? '',
    location: pickup.location,
    readyAtDate: dateHelper.displayDate(pickup.date, config.format.datePicker),
    readyAtTime: entities.pickup.getPickupTime(pickup.startTime),
    closesAtTime: entities.pickup.getPickupTime(pickup.endTime),
    pickupNotes: pickup.notes,
    shipmentId: data.shipmentId
  };

  return body;
}

function getAddShipmentToPickupRequest(data: AddShipmentToPickupDto | null): AddShipmentToPickupRequest | null {
  if (!data || !data.pickupId) return null;

  const body: AddShipmentToPickupRequest = {
    pickup: getIri(data.pickupId, 'pickups'),
    shipmentId: data.shipmentId
  };

  return body;
}
