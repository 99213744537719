import {isEmpty, get, isBoolean, isNumber, isDate, isArray} from 'lodash';
import libphonenumber from 'google-libphonenumber';
import {isValidPhoneNumber as isValidPhone} from 'react-phone-number-input';

import ERROR from 'constants/literals/errors';
import ELECTRONIC_EXPORT_FILING from 'constants/electronicExportFilings';
import DRAFT_STEP from 'constants/draftSteps';
import COMMON from 'constants/common';
import UNIT from 'constants/units';
import COUNTRY_CODE from 'constants/countryCodes/countryCodes';
import US_TERRITORIES from 'constants/countryCodes/usTerritories';
import FEATURE from 'constants/features';
import PAYMENT_TIME from 'constants/paymentTime';
import PICKUP_FORMAT from 'constants/pickupFormat';
import ACCOUNT_NUMBER_FORMAT from 'constants/accountNumberFormat';
import SHIPMENT_FORMAT from 'constants/shipmentFormat';
import CARRIER from 'constants/carriers';
import INVOICE_TYPE from 'constants/invoiceTypes';

import unitHelper from './unitHelper';

interface LimitValue {
  length: number;
  width: number;
  height: number;
}

export default {
  isValidEmail,
  isValidPhoneNumber,
  validateCountriesForShipment,
  isEmptyErrorObject,
  validateShipment,
  validatePickupToSave,
  validateRateRequest,
  validateStandaloneQuote,
  checkPackageExceedsSizeLimit,
  validateStandalonePickup,
  getWeightLimit,
  checkWeightIsUnderLimit,
  totalWeightIsUnderLimit,
  checkDeclaredValueExceedsLimit,
  getInputError,
  validateContact,
  validateCommodityItem,
  validateBillingContactInformation,
  validatePaymentMethod
};

const EMAIL_VALIDATION_REG_EXP = /^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
const COMMODITY_CODE_REG_EXP = /^(\d{4}\.\d{2}\.\d{4})$/;
const ITN_NUMBER_REG_EXP = /^X[0-9]{14}$/;
const EIN_NUMBER_REG_EXP = /^\d+$/;
const USA_POSTAL_CODE_REG_EXP = /^[0-9]{5}(?:-[0-9]{4})?$/;

function isValidEmail(email: string): boolean {
  if (!email) return true;

  return EMAIL_VALIDATION_REG_EXP.test(email);
}

function isValidPhoneNumber(phoneNumber, countryCode = '') {
  if (!phoneNumber) return false;

  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

  let phoneInstance = null;

  try {
    phoneInstance = phoneUtil.parse(phoneNumber, countryCode);

    if (!countryCode) countryCode = phoneUtil.getRegionCodeForNumber(phoneInstance);

    if (phoneInstance) return phoneUtil.isValidNumberForRegion(phoneInstance, countryCode);
  } catch {}

  return isValidPhone(phoneNumber);
}

function validateCountriesForShipment(originCountry: string, destinationCountry: string): ValidationError | undefined {
  if (originCountry && destinationCountry && originCountry !== COUNTRY_CODE.USA && (originCountry === destinationCountry)) {
    return {
      message: 'DHL does not service shipments within other countries. A local carrier within that country will need to handle those shipments. Please make another country selection.',
      isWarning: false
    };
  }

  if (US_TERRITORIES.includes(originCountry) || US_TERRITORIES.includes(destinationCountry)) {
    return {
      message: 'US Territory rates are not available online. Please contact our customer service team to receive your quote. You can still prepare your shipment.',
      isWarning: true
    }
  }
}

function isEmptyErrorObject(obj): boolean {
  let isEmptyObject = true;

  for (let key of Object.keys(obj)) {
    let value = obj[key];

    if (!isEmpty(value)) {
      isEmptyObject = false;
      break;
    }
  }

  return isEmptyObject;
}

function validateShipment(
  shipment: Draft,
  step,
  countryOptions,
  paymentRequired: boolean,
  paymentTime: string,
  rates: Rate[],
  carrier = ''
): ValidationResult {
  const shipmentFormat = SHIPMENT_FORMAT[shipment.isDomestic ? COMMON.DOMESTIC : COMMON.INTERNAIONAL];

  let shipmentCriteria: any = [
    fieldRequired('shipmentFrom.companyName', 'Company name'),
    fieldLength('shipmentFrom.companyName', 'Company name', 2),
    fieldLengthExceeded('shipmentFrom.companyName', 'Company name', shipmentFormat.COMPANY_NAME.MAX_LENGTH),
    fieldRequired('shipmentFrom.contactName', 'Contact name'),
    fieldLength('shipmentFrom.contactName', 'Contact name'),
    fieldLengthExceeded('shipmentFrom.contactName', 'Contact name', shipmentFormat.CONTACT_NAME.MAX_LENGTH),
    fieldRequired('shipmentFrom.country', 'Country'),
    fieldRequired('shipmentFrom.phoneNumber', 'Phone number'),
    fieldLengthExceeded('shipmentFrom.phoneNumber', 'Phone number', 25),
    phoneCriteria('shipmentFrom.phoneNumber', 'Phone number'),
    fieldLengthExceeded('shipmentFrom.phoneNumberExtension', 'Extension', 5),
    emailCriteria('shipmentFrom.email'),
    fieldLengthExceeded('shipmentFrom.email', 'Email', 60),
    fieldRequired('shipmentFrom.address.addressLine1', 'Address line 1'),
    fieldLength('shipmentFrom.address.addressLine1', 'Address line 1', 2),
    fieldLengthExceeded('shipmentFrom.address.addressLine1', 'Address line 1', shipmentFormat.ADDRESS_LINE.MAX_LENGTH),
    fieldLengthExceeded('shipmentFrom.address.addressLine2', 'Address line 2', shipmentFormat.ADDRESS_LINE.MAX_LENGTH),
    fieldLengthExceeded('shipmentFrom.address.addressLine3', 'Address line 3', shipmentFormat.ADDRESS_LINE.MAX_LENGTH),
    fieldLengthExceeded('shipmentFrom.taxId', 'Tax Id', 20),

    fieldRequired('shipmentTo.companyName', 'Company name'),
    fieldLength('shipmentTo.companyName', 'Company name', 2),
    fieldLengthExceeded('shipmentTo.companyName', 'Company name', shipmentFormat.COMPANY_NAME.MAX_LENGTH),
    fieldRequired('shipmentTo.contactName', 'Contact name'),
    fieldLength('shipmentTo.contactName', 'Contact name'),
    fieldLengthExceeded('shipmentTo.contactName', 'Contact name', shipmentFormat.CONTACT_NAME.MAX_LENGTH),
    fieldRequired('shipmentTo.country', 'Country'),
    fieldRequired('shipmentTo.phoneNumber', 'Phone number'),
    fieldLengthExceeded('shipmentTo.phoneNumber', 'Phone number', 25),
    phoneCriteria('shipmentTo.phoneNumber', 'Phone number'),
    fieldLengthExceeded('shipmentTo.phoneNumberExtension', 'Extension', 5),
    emailCriteria('shipmentTo.email'),
    fieldLengthExceeded('shipmentTo.email', 'Email', 60),
    fieldRequired('shipmentTo.address.addressLine1', 'Address line 1'),
    fieldLengthExceeded('shipmentTo.address.addressLine1', 'Address line 1', shipmentFormat.ADDRESS_LINE.MAX_LENGTH),
    fieldLengthExceeded('shipmentTo.address.addressLine2', 'Address line 2', shipmentFormat.ADDRESS_LINE.MAX_LENGTH),
    fieldLengthExceeded('shipmentTo.address.addressLine3', 'Address line 3', shipmentFormat.ADDRESS_LINE.MAX_LENGTH),
    fieldLengthExceeded('shipmentTo.taxId', 'Tax Id', 20)
  ];

  shipmentCriteria = getTotalAddressLengthExceededCriteria(
    shipmentCriteria,
    shipment.shipmentFrom.address,
    shipmentFormat.ADDRESS_LINE.TOTAL_MAX_LENGTH,
    'shipmentFrom.address.addressLine'
  );

  let addressCriteria: any = [];

  getValidationCriteriaForAddress(addressCriteria, shipment, countryOptions, 'shipmentFrom');
  getValidationCriteriaForAddress(addressCriteria, shipment, countryOptions, 'shipmentTo');

  let shipmentDetailsCriteria: any = [];

  if (shipment.isDomestic) {
    shipmentDetailsCriteria = getValidationCriteriaForPackages(shipmentDetailsCriteria, shipment, carrier);

    if (shipment.shipmentDetails.products.isShipmentContainsDangerousGoods) {
      shipmentDetailsCriteria.push({
        field: 'shipmentDetails.products.isShipmentContainsDangerousGoods',
        test: obj => false,
        message: ERROR.DANGEROUS_GOODS
      });
    }
  }

  if (shipment.isDocumentsShipment) {
    const documentShipmentDetailsCriteria = [
      booleanFieldRequired('shipmentDetails.documents.isDocumentWeightAboveHalfPound', 'Weight of your shipment'),
      booleanValueExpected(
        'shipmentDetails.documents.isItJustDocument',
        true,
        'If your shipment contains more than just document(s), you should ship it as a product'
      ),
      numberFieldRequired('shipmentDetails.documents.numberOfPackages', 'Number of packages'),
      fieldRequired('shipmentDetails.documents.typeOfPackaging', 'Type of packaging'),
      fieldRequired('shipmentDetails.documents.generalDescription', 'General description'),
      fieldLength('shipmentDetails.documents.generalDescription', 'General description'),
      fieldLengthExceeded('shipmentDetails.documents.generalDescription', 'General description', 35)
    ];

    shipmentDetailsCriteria = shipmentDetailsCriteria.concat(documentShipmentDetailsCriteria);

    if (shipment.additionalServices.doYouNeedInsurance) {
      shipmentDetailsCriteria.push(numberFieldRequired(
        'shipmentDetails.documents.declaredShipmentValue',
        'Declared shipment value',
        'You must declare a shipment value if you insure the shipment.'));
    }

    if (shipment.shipmentDetails.documents.isDocumentWeightAboveHalfPound) {
      shipmentDetailsCriteria.push(
        numberFieldRequired(
          'shipmentDetails.documents.documentWeight',
          '',
          'Please fill in exact weight of your shipment!'
        )
      );

      shipmentDetailsCriteria.push(measurementFieldBelowMinimum(`shipmentDetails.documents.documentWeight`, 'WEIGHT'));

      if (
        !checkWeightIsUnderLimit(
          shipment.shipmentDetails.documents.documentWeight,
          COMMON.DOCUMENT,
          shipment.measurementSystem
        )
      ) {
        shipmentDetailsCriteria.push({
          field: 'shipmentDetails.documents.documentWeight',
          test: obj => false,
          message: 'If your documents weighs more than 5.0 lbs, it has to be shipped as a product!'
        });
      }
    }
  }

  if (!shipment.isDomestic && !shipment.isDocumentsShipment) {
    shipmentDetailsCriteria = getValidationCriteriaForPackages(shipmentDetailsCriteria, shipment, carrier);

    if (shipment.shipmentDetails.products.isShipmentContainsDangerousGoods) {
      shipmentDetailsCriteria.push({
        field: 'shipmentDetails.products.isShipmentContainsDangerousGoods',
        test: obj => false,
        message: ERROR.DANGEROUS_GOODS
      });
    }

    const declaredShipmentValue = {
      name: 'Declared shipment value',
      path: 'shipmentDetails.products.declaredShipmentValue',
      value: shipment.shipmentDetails.products.declaredShipmentValue
    };

    const productShipmentDetailsCriteria = [
      booleanFieldRequired('shipmentDetails.products.isAnyBatteries', '', 'Please answer Yes or No'),
      fieldRequired('shipmentDetails.products.generalDescription', 'General description'),
      fieldLength('shipmentDetails.products.generalDescription', 'General description'),
      fieldLengthExceeded('shipmentDetails.products.generalDescription', 'General description', 90),
      numberFieldRequired(declaredShipmentValue.path, declaredShipmentValue.name)
    ];

    shipmentDetailsCriteria = shipmentDetailsCriteria.concat(productShipmentDetailsCriteria);

    if (declaredShipmentValue.value && shipment.invoiceTotalSum && declaredShipmentValue.value !== shipment.invoiceTotalSum) {
      shipmentDetailsCriteria.push({
        field: declaredShipmentValue.path,
        test: obj => false,
        message: 'Declared Value must match Total Value!'
      });
    }

    shipmentDetailsCriteria = shipmentDetailsCriteria.concat(getValidationCriteriaForInsurance(shipment));

    if (shipment.isExport) {
      shipmentDetailsCriteria = getValidationCriteriaForElectronicExportFiling(shipmentDetailsCriteria, shipment);
    }

    shipmentDetailsCriteria = getValidationCriteriaForInvoice(shipmentDetailsCriteria, shipment);
  }

  let criteria: any = shipmentCriteria.concat(addressCriteria, shipmentDetailsCriteria);

  if (step !== DRAFT_STEP.CREATE_SHIPMENT) {
    let pickupCriteria = getValidationCriteriaForPickup(shipment, rates);

    criteria = criteria.concat(pickupCriteria);
  }

  if (step === DRAFT_STEP.PAYMENT) {
    let paymentCriteria = getValidationCriteriaForPayment(shipment, paymentTime, carrier);

    criteria = criteria.concat(paymentCriteria);
  }

  let result = validator(shipment, criteria);

  if (step !== DRAFT_STEP.CREATE_SHIPMENT) {
    let isPickupRequired = shipment.pickupDelivery.isPickupRequired;

    if (isPickupRequired && !(shipment.pickupDelivery?.pickup || shipment.pickupDelivery?.pickupId)) {
      result.valid = false;
      result.errors[COMMON.ERROR_NOTIFICATIONS] = ['Please schedule a pickup before proceeding or select no pickup.'];
    }
  }

  if (FEATURE.PAYMENT_AUTH && step === DRAFT_STEP.PAYMENT) {
    if (paymentRequired) {
      result.valid = false;
      result.errors[COMMON.ERROR_NOTIFICATIONS] = ['Please add payment method before proceeding.'];
    }
  }

  return result;
}

function validateRateRequest(shipment: Draft, carrier?: string): ValidationResult {
  let criteria: any = [
    fieldRequired('shipmentFrom.country', 'Country'),
    fieldRequired('shipmentTo.country', 'Country'),
    fieldLengthExceeded('shipmentFrom.address.postalCode', 'Postal code', 12),
    fieldLengthExceeded('shipmentTo.address.postalCode', 'Postal code', 12)
  ];

  if (shipment.isDomestic) {
    criteria.push(fieldRequired('shipmentFrom.address.postalCode', 'Postal code'));
    criteria.push(fieldRequired('shipmentTo.address.postalCode', 'Postal code'));
    criteria.push(usPostalCodeCriteria('shipmentFrom.address.postalCode'));
    criteria.push(usPostalCodeCriteria('shipmentTo.address.postalCode'));
  }

  if (shipment.isDocumentsShipment) {
    criteria.push(numberFieldRequired('shipmentDetails.documents.numberOfPackages', 'Number of packages'));
    criteria.push(fieldRequired('shipmentDetails.documents.typeOfPackaging', 'Type of packaging'));

    if (shipment.shipmentDetails.documents.isDocumentWeightAboveHalfPound) {
      criteria.push(numberFieldRequired('shipmentDetails.documents.documentWeight', 'Document weight'));
    }
  } else {
    shipment.shipmentDetails.products.packages.forEach((item, index) => {
      criteria.push(numberFieldRequired(`shipmentDetails.products.packages[${index}].weight`, 'Weight'));
      criteria.push(numberFieldRequired(`shipmentDetails.products.packages[${index}].height`, 'Height'));
      criteria.push(numberFieldRequired(`shipmentDetails.products.packages[${index}].width`, 'Width'));
      criteria.push(numberFieldRequired(`shipmentDetails.products.packages[${index}].length`, 'Length'));
      criteria.push(numberFieldRequired(`shipmentDetails.products.packages[${index}].numberOfPackages`, 'Number of packages'));
    });
  }

  let result = validator(shipment, criteria);

  // Check if freight limits have been exceeded.
  let weightIsUnderLimit = true;
  let totalIsUnderLimit = true;
  let totalWeight = 0;

  const measurementSystem = shipment.measurementSystem;
  let carrierforWeightLimits;

  if (shipment.isDocumentsShipment) {
    carrierforWeightLimits = COMMON.DOCUMENT;
  } else if (carrier) {
    carrierforWeightLimits = carrier;
  } else if (shipment.isDomestic) {
    carrierforWeightLimits = COMMON.DOMESTIC;
  } else {
    carrierforWeightLimits = CARRIER.DHL;
  }

  if (shipment.isDocumentsShipment) {
    const documentWeight = shipment.shipmentDetails.documents.documentWeight;

    weightIsUnderLimit = checkWeightIsUnderLimit(Number(documentWeight), carrierforWeightLimits, measurementSystem);
  } else {
    shipment.shipmentDetails.products.packages.forEach((item) => {
      totalWeight += (Number(item.weight) * Number(item.numberOfPackages));

      weightIsUnderLimit = checkWeightIsUnderLimit(Number(item.weight), carrierforWeightLimits, measurementSystem);
      if (!weightIsUnderLimit) return;
    });
  }

  if (weightIsUnderLimit) {
    totalIsUnderLimit = totalWeightIsUnderLimit(totalWeight, carrierforWeightLimits, measurementSystem);
  }

  if (!weightIsUnderLimit || !totalIsUnderLimit) {
    result.valid = false;
    result.errors[COMMON.ERROR_NOTIFICATIONS] = ['Package weights have exceeded limit.'];
  }

  return result;
}

function validateStandaloneQuote(standaloneQuote: StandaloneQuote, isDomestic: boolean): ValidationResult {
  const measurementSystem = standaloneQuote.measurementSystem;

  let criteria: any = [
    fieldRequired('sendingFrom.country', 'Sending Country'),
    fieldRequired('sendingTo.country', 'Destination Country')
  ];

  if (isDomestic) {
    criteria.push(fieldRequired('sendingFrom.postalCode', 'Zip code'));
    criteria.push(fieldRequired('sendingTo.postalCode', 'Zip code'));
  }

  if (standaloneQuote.sendingFrom.postalCode && standaloneQuote.sendingFrom.country === COUNTRY_CODE.USA) {
    criteria.push(usPostalCodeCriteria('sendingFrom.postalCode'));
  }

  if (standaloneQuote.sendingTo.postalCode && standaloneQuote.sendingTo.country === COUNTRY_CODE.USA) {
    criteria.push(usPostalCodeCriteria('sendingTo.postalCode'));
  }

  criteria.push(fieldLengthExceeded('sendingFrom.postalCode', 'Postal code', 12));
  criteria.push(fieldLengthExceeded('sendingTo.postalCode', 'Postal code', 12));

  standaloneQuote.items.forEach((item, index) => {
    criteria.push(numberFieldRequired(`items[${index}].quantity`, '', 'At least one package is required'));
    criteria.push(
      numberFieldRequired(`items[${index}].weight`, '', unitHelper.getStandaloneWeightLimitMessage(measurementSystem, isDomestic, standaloneQuote.isDocument))
    );
    criteria.push(measurementFieldBelowMinimum(`items[${index}].weight`, 'WEIGHT'));

    if (!standaloneQuote.isDocument) {
      criteria.push(
        numberFieldRequired(`items[${index}].height`, '', unitHelper.getStandaloneHeightLimitMessage(measurementSystem, isDomestic))
      );
      criteria.push(measurementFieldBelowMinimum(`items[${index}].height`, 'DIMENSION'));
      criteria.push(numberFieldRequired(`items[${index}].width`, 'Width'));
      criteria.push(measurementFieldBelowMinimum(`items[${index}].width`, 'DIMENSION'));
      criteria.push(numberFieldRequired(`items[${index}].length`, 'Length'));
      criteria.push(measurementFieldBelowMinimum(`items[${index}].length`, 'DIMENSION'));
    }
  });

  return validator(standaloneQuote, criteria);
}

function checkPackageExceedsSizeLimit(
  items: StandaloneItem[],
  carrier: string,
  isDomestic: boolean,
  measurementSystem: string
): boolean {
  const packageLimits = require('../data/packageLimits.json');

  const carrierPackageLimits = packageLimits?.[carrier];
  if (!carrierPackageLimits) return false;

  const unit: string = unitHelper.getUnitFromMeasurementSystem(measurementSystem, false);
  const limitValue: number | LimitValue = carrierPackageLimits[unit];

  let packageExceedsSizeLimit = false;

  items.forEach(item => {
    const heightLimit = typeof limitValue === 'number' ? limitValue : limitValue.height;
    if (item.height > heightLimit) {
      packageExceedsSizeLimit = true;
      return;
    }

    const lengthLimit = typeof limitValue === 'number' ? limitValue : limitValue.length;
    if (item.length > lengthLimit) {
      packageExceedsSizeLimit = true;
      return;
    }

    const widthLimit = typeof limitValue === 'number' ? limitValue : limitValue.width;
    if (item.width > widthLimit) {
      packageExceedsSizeLimit = true;
      return;
    }

    if (isDomestic) {
      const domesticLimit =
        unit === UNIT.LENGTH_INCH
          ? COMMON.DOMESTIC_QUOTE_SIZE_LIMIT_IN_INCH
          : COMMON.DOMESTIC_QUOTE_SIZE_LIMIT_IN_INCH * 2.54;

      const girth = item.width * 2 + item.height * 2;
      const size = Number(item.length) + girth;

      if (size > domesticLimit) {
        packageExceedsSizeLimit = true;
        return;
      }
    }
  });

  return packageExceedsSizeLimit;
}

function validateStandalonePickup(standalonePickup: StandalonePickup, countryOptions): ValidationResult {
  const carrier: string = standalonePickup.pickup.carrier;

  let mainCriteria: any = [
    fieldRequired('origin.companyName', 'Company name'),
    fieldLength('origin.companyName', 'Company name', 2),
    fieldLengthExceeded('origin.companyName', 'Company name', PICKUP_FORMAT[carrier].COMPANY_NAME.MAX_LENGTH),
    fieldRequired('origin.contactName', 'Contact name'),
    fieldLength('origin.contactName', 'Contact name'),
    fieldLengthExceeded('origin.contactName', 'Contact name', PICKUP_FORMAT[carrier].CONTACT_NAME.MAX_LENGTH),
    fieldRequired('origin.country', 'Country'),
    fieldRequired('origin.phoneNumber', 'Phone number'),
    fieldLengthExceeded('origin.phoneNumber', 'Phone number', 25),
    phoneCriteria('origin.phoneNumber', 'Phone number'),
    fieldLengthExceeded('origin.phoneNumberExtension', 'Extension', 5),
    fieldRequired('origin.email', 'Email address'),
    emailCriteria('origin.email'),
    fieldLengthExceeded('origin.email', 'Email', 60),
    fieldRequired('origin.address.addressLine1', 'Address line 1'),
    fieldLengthExceeded('origin.address.addressLine1', 'Address line 1', PICKUP_FORMAT[carrier].ADDRESS_LINE.MAX_LENGTH),
    fieldLengthExceeded('origin.address.addressLine2', 'Address line 2', PICKUP_FORMAT[carrier].ADDRESS_LINE.MAX_LENGTH),
    fieldLengthExceeded('origin.address.addressLine3', 'Address line 3', PICKUP_FORMAT[carrier].ADDRESS_LINE.MAX_LENGTH),
    fieldRequired('pickup.location', 'Location of pickup'),
    fieldRequired('pickup.carrier', 'Carrier'),
    fieldLengthExceeded('pickup.notes', 'Notes', PICKUP_FORMAT[carrier].NOTES.MAX_LENGTH)
  ];

  mainCriteria = getTotalAddressLengthExceededCriteria(
    mainCriteria,
    standalonePickup.origin.address,
    PICKUP_FORMAT[carrier].ADDRESS_LINE.TOTAL_MAX_LENGTH,
    'origin.address.addressLine'
  );

  let addressCriteria: any = [];

  getValidationCriteriaForAddress(addressCriteria, standalonePickup, countryOptions, 'origin');

  let criteria: any = mainCriteria.concat(addressCriteria);

          // Check for same day FedEx Ground pickup
          if (standalonePickup.pickup.carrierProduct === CARRIER.FED_EX_GROUND_PICKUP
            || standalonePickup.pickup.carrierProduct  === CARRIER.FED_EX_HOME_DELIVERY) {
            const pickupDateTime = standalonePickup.pickup.date;
            if (pickupDateTime) {
              const pickupDate = new Date(Date.UTC(pickupDateTime.getFullYear(), pickupDateTime.getMonth(), pickupDateTime.getDate()));

              const now = new Date();
              const today = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));

              // eslint-disable-next-line
                if (pickupDate.getTime() == today.getTime()) {
                  criteria.push({
                    field: 'pickup.date',
                    test: obj => false,
                    message: 'FedEx does not allow same day pickups for ground services. Please choose a different day for pickup or switch to an express service.'
                  });
                }
            }
          }

  return validator(standalonePickup, criteria);
}

function getWeightLimit(carrier: string, measurementSystem: string, isTotalWeight = false): number {
  const fileName = isTotalWeight ? 'packagesTotalWeightLimit.json' : 'packageLimits.json';

  const limits = require(`../data/${fileName}`);

  const carrierPackageLimits = limits?.[carrier];

  const unit: string = unitHelper.getUnitFromMeasurementSystem(measurementSystem, true);

  return carrierPackageLimits?.[unit];
}

function checkWeightIsUnderLimit(weight: number, carrier = '', measurementSystem: string): boolean {
  if (!carrier) return true;

  const weightLimit: number = getWeightLimit(carrier, measurementSystem);

  if (weight > weightLimit) return false;

  return true;
}

function totalWeightIsUnderLimit(totalWeight: number, carrier = '', measurementSystem: string): boolean {
  if (!carrier) return true;

  const weightLimit: number = getWeightLimit(carrier, measurementSystem, true);

  if (totalWeight > weightLimit) return false;

  return true;
}

function checkDeclaredValueExceedsLimit(declaredValue: number, carrier?: string) {
  if (carrier === CARRIER.UPS && declaredValue >= COMMON.DECLARED_VALUE_WARNING_MIN_VALUE) {
    return true;
  }
  return false;
}

function getInputError(error?: string | ValidationError[]): string {
  if (!error) return '';

  const errorItem: string | ValidationError = isArray(error) ? error[0] : error;

  if (typeof errorItem === 'string') return errorItem;

  return errorItem?.message ?? '';
}

function validateContact(contact: Sending, countryOptions): ValidationResult {
  let criteria: any = [
    fieldRequired('companyName', 'Company name'),
    fieldLength('companyName', 'Company name', 2),
    fieldLengthExceeded('companyName', 'Company name', 60),
    fieldRequired('contactName', 'Contact name'),
    fieldLength('contactName', 'Contact name'),
    fieldLengthExceeded('contactName', 'Contact name', 35),
    fieldRequired('country', 'Country'),
    fieldRequired('phoneNumber', 'Phone number'),
    fieldLengthExceeded('phoneNumber', 'Phone number', 25),
    phoneCriteria('phoneNumber', 'Phone number'),
    fieldLengthExceeded('phoneNumberExtension', 'Extension', 5),
    emailCriteria('email'),
    fieldLengthExceeded('email', 'Email', 60),
    fieldRequired('address.addressLine1', 'Address line 1'),
    fieldLengthExceeded('address.addressLine1', 'Address line 1', 45),
    fieldLengthExceeded('address.addressLine2', 'Address line 2', 45),
    fieldLengthExceeded('address.addressLine3', 'Address line 3', 45),
    fieldLengthExceeded('taxId', 'Tax Id', 20)
  ];

  let addressCriteria: any = [];

  getValidationCriteriaForAddress(addressCriteria, contact, countryOptions);

  criteria = criteria.concat(addressCriteria);

  return validator(contact, criteria);
}

function validateCommodityItem(item: LineItem): ValidationResult {
  const criteria = [
    fieldRequired('name', 'Name'),
    fieldRequired('commodityCode', 'Commodity code'),
    regexCriteria('commodityCode', COMMODITY_CODE_REG_EXP, ERROR.COMMODITY_CODE),
    fieldRequired('itemDescription', 'Description'),
    fieldLengthExceeded('itemDescription', 'Description', 75),
    numberFieldRequired('quantity', 'Quantity'),
    fieldRequired('units', 'Units'),
    fieldRequired('itemMade', 'Country'),
    numberFieldRequired('itemValue', 'Value'),
    numberFieldRequired('weight', 'Weight'),
    numberFieldBelowMinimum('weight', 'Weight', 0.001)
  ];

  return validator(item, criteria);
}

function validateBillingContactInformation(billingContactInformation: BillingContactInformation): ValidationResult {
  let criteria = [
    fieldRequired('contactName', 'Contact name'),
    fieldLength('contactName', 'Contact name'),
    fieldLengthExceeded('contactName', 'Contact name', 35),
    fieldRequired('phoneNumber', 'Phone number'),
    fieldLengthExceeded('phoneNumber', 'Phone number', 25),
    phoneCriteria('phoneNumber', 'Phone number'),
    fieldLengthExceeded('phoneNumberExtension', 'Extension', 5),
    fieldRequired('email', 'Email address'),
    emailCriteria('email'),
    fieldLengthExceeded('email', 'Email', 60)
  ];

  return validator(billingContactInformation, criteria);
}

function validatePaymentMethod(data: SavePaymentMethodDto, countryOptions): ValidationResult {
  const dataObject = {
    ...data,
    address: {...data.billingAddress}
  };

  let criteria = [
    fieldRequired('address.addressLine1', 'Address line 1'),
    fieldLengthExceeded('address.addressLine1', 'Address line 1', 45),
    fieldLengthExceeded('address.addressLine2', 'Address line 2', 45),
    fieldLengthExceeded('address.addressLine3', 'Address line 3', 45)
  ];

  if (data.creditCard) {
    criteria.push(fieldRequired('creditCard.cardholderFirstName', 'Cardholder name'));
  }

  if (data.achAccount) {
    criteria.push(fieldRequired('achAccount.accountNumber', 'Account number'));
    criteria.push(fieldRequired('achAccount.routingNumber', 'Routing number'));
    criteria.push(fieldRequired('achAccount.nameOnAccount', 'Account holder name'));
    criteria.push(fieldRequired('achAccount.bankName', 'Bank name'));
  }

  let addressCriteria: any = [];

  getValidationCriteriaForAddress(addressCriteria, {...dataObject, country: COUNTRY_CODE.USA}, countryOptions);

  criteria = criteria.concat(addressCriteria);

  return validator(dataObject, criteria);
}

//helper methods

function getValidationCriteriaForAddress(addressCriteria, data, countryOptions, path = '') {
  let countryOption: CountryOption = countryOptions.find(item => {
    if (path) return item.code === data[path].country;
    return item.code === data.country;
  });

  if (!countryOption) return addressCriteria;

  const getPath = key => {
    if (path) return `${path}.address.${key}`;
    return `address.${key}`;
  };

  const elements = countryOption.elements;

  if (elements.city || elements.city === false) {
    addressCriteria.push(fieldLengthExceeded(getPath('city'), 'City', 35));
    if (elements.city) {
      addressCriteria.push(fieldRequired(getPath('city'), 'City'));
    }
  }
  if (elements.postalCode || elements.postalCode === false) {
    addressCriteria.push(fieldLengthExceeded(getPath('postalCode'), 'Postal code', 12));

    if (countryOption.code === COUNTRY_CODE.USA) {
      addressCriteria.push(usPostalCodeCriteria(getPath('postalCode')));
    }

    if (elements.postalCode) {
      addressCriteria.push(fieldRequired(getPath('postalCode'), 'Postal code'));
    }
  }
  if (elements.suburb || elements.suburb === false) {
    addressCriteria.push(fieldLengthExceeded(getPath('suburb'), 'Suburb', 35));

    if (elements.suburb) {
      addressCriteria.push(fieldRequired(getPath('suburb'), 'Suburb'));
    }
  }
  if (elements.county || elements.county === false
    || elements.state || elements.state === false
    || elements.district || elements.district === false
    || elements.province || elements.province === false) {
    let division = '';
    if (elements.county || elements.county === false) division = 'County';
    if (elements.state || elements.state === false) division = 'State';
    if (elements.district || elements.district === false) division = 'District';
    if (elements.province || elements.province === false) division = 'Province';

    addressCriteria.push(fieldLengthExceeded(getPath('division'), division, 35));

    if (elements.county || elements.state || elements.district || elements.province) {
      addressCriteria.push(fieldRequired(getPath('division'), division));
    }
  }

  return addressCriteria;
}

function getValidationCriteriaForElectronicExportFiling(shipmentDetailsCriteria, shipment: Draft) {
  const electronicExportFiling = shipment.shipmentDetails.products.electronicExportFiling;

  let otherExportFiling = electronicExportFiling === ELECTRONIC_EXPORT_FILING.OTHER;
  let noExportFiling = electronicExportFiling === ELECTRONIC_EXPORT_FILING.NO;

  if (otherExportFiling) {
    shipmentDetailsCriteria.push(fieldRequired('shipmentDetails.products.foreignTradeRegulations', 'FTR'));
  }

  if (noExportFiling) {
    shipmentDetailsCriteria.push(
      booleanFieldRequired('shipmentDetails.products.isItnNumberExists', 'Please make your choice')
    );

    let isItnNumberExists = shipment.shipmentDetails.products.isItnNumberExists;

    if (isItnNumberExists === true) {
      const itnPath = 'shipmentDetails.products.ITNNumber';
      shipmentDetailsCriteria.push(fieldRequired(itnPath, 'ITN number'));
      shipmentDetailsCriteria.push(
        regexCriteria(
          itnPath,
          ITN_NUMBER_REG_EXP,
          'ITN number is not valid. Valid number starts with an X, followed by 14 numbers.'
        )
      );
    }

    if (isItnNumberExists === false) {
      const einPath = 'shipmentDetails.products.EINNumber';
      shipmentDetailsCriteria.push(fieldRequired(einPath, 'EIN number'));
      shipmentDetailsCriteria.push(
        regexCriteria(einPath, EIN_NUMBER_REG_EXP, 'EIN is not valid. It should contain only numbers.')
      );
      shipmentDetailsCriteria.push(einFieldLengthExceeded(einPath, get(shipment, einPath)?.length));
    }
  }

  return shipmentDetailsCriteria;
}

function getValidationCriteriaForInvoice(shipmentDetailsCriteria, shipment: Draft) {
  if (shipment.isInvoiceItemsRequired) {
    let validateItemExport = shipment.shipmentDetails.products.isItnNumberExists === false;

    shipment.shipmentDetails.products.items.forEach((invoiceItem: ProductItem, index) => {
      shipmentDetailsCriteria.push(
        fieldRequired(`shipmentDetails.products.items[${index}].commodityCode`, 'Commodity code')
      );
      shipmentDetailsCriteria.push(
        regexCriteria(
          `shipmentDetails.products.items[${index}].commodityCode`,
          COMMODITY_CODE_REG_EXP,
          ERROR.COMMODITY_CODE
        )
      );
      shipmentDetailsCriteria.push(
        fieldRequired(`shipmentDetails.products.items[${index}].itemDescription`, 'Description')
      );
      shipmentDetailsCriteria.push(
        fieldLengthExceeded(`shipmentDetails.products.items[${index}].itemDescription`, 'Description', 75)
      );
      shipmentDetailsCriteria.push(
        numberFieldRequired(`shipmentDetails.products.items[${index}].quantity`, 'Quantity')
      );
      shipmentDetailsCriteria.push(fieldRequired(`shipmentDetails.products.items[${index}].units`, 'Units'));
      shipmentDetailsCriteria.push(numberFieldRequired(`shipmentDetails.products.items[${index}].itemValue`, 'Value'));
      shipmentDetailsCriteria.push(fieldRequired(`shipmentDetails.products.items[${index}].itemMade`, 'Country'));
      shipmentDetailsCriteria.push(numberFieldRequired(`shipmentDetails.products.items[${index}].weight`, 'Weight'));
      shipmentDetailsCriteria.push(numberFieldBelowMinimum(`shipmentDetails.products.items[${index}].weight`, 'Weight', 0.001));

      if (validateItemExport) {
        if (invoiceItem.isLicenseDetailsRequired) {
          shipmentDetailsCriteria.push(
            dateRequired(`shipmentDetails.products.items[${index}].exportLicenseExpiryDate`, 'Expire date')
          );

          if (shipment.shipmentDetails.products.items[index].isLicenseSymbolRequired) {
            shipmentDetailsCriteria.push(
              fieldRequired(`shipmentDetails.products.items[${index}].licenseSymbol`, 'License symbol')
            );
            shipmentDetailsCriteria.push(
              fieldLengthExceeded(`shipmentDetails.products.items[${index}].licenseSymbol`, 'License symbol', 16)
            );
          }
        }
      }

      if (invoiceItem.isEccnRequired) {
        shipmentDetailsCriteria.push(
          fieldLengthExceeded(`shipmentDetails.products.items[${index}].eccnValue`, 'ECCN', 30)
        );
      }

      let totalSum: number = shipment.invoiceTotalSum;
      if (totalSum && totalSum !== shipment.shipmentDetails.products.declaredShipmentValue) {
        shipmentDetailsCriteria.push(fieldRequired('totalsum', 'Total sum'));
      }
    });
  }

  if (!shipment.isDutiable) return shipmentDetailsCriteria;

  shipmentDetailsCriteria.push(fieldRequired('shipmentDetails.products.invoiceType', 'Invoice type'));
  shipmentDetailsCriteria.push(checkForExternalInvoiceType('shipmentDetails.products.invoiceType', 'Invoice type'));
  shipmentDetailsCriteria.push(fieldRequired('shipmentDetails.products.exportType', 'Type of export'));

  if (shipment.isProformaInvoiceType) {
    shipmentDetailsCriteria.push(fieldRequired('shipmentDetails.products.purposeOfShipment', 'Purpose of shipment'));
  }

  if (shipment.shipmentDetails.products.isShipmentReferenceNumberExists) {
    shipmentDetailsCriteria.push(fieldRequired('shipmentDetails.products.shipmentReferenceNumber', 'Reference number'));
    shipmentDetailsCriteria.push(
      fieldLengthExceeded('shipmentDetails.products.shipmentReferenceNumber', 'Reference number', 35)
    );
  }

  if (shipment.shipmentDetails.products.isInvoiceNumberExists) {
    shipmentDetailsCriteria.push(fieldRequired('shipmentDetails.products.invoiceNumber', 'Invoice number'));
  }

  if (shipment.shipmentDetails.products.isReceiverReferenceExists) {
    shipmentDetailsCriteria.push(fieldRequired('shipmentDetails.products.receiverReference', 'Receiver reference'));
  }

  if (shipment.shipmentDetails.products.isAnyPackageMarks) {
    shipmentDetailsCriteria.push(fieldRequired('shipmentDetails.products.packageMarks', 'Package marks'));
    shipmentDetailsCriteria.push(fieldLengthExceeded('shipmentDetails.products.packageMarks', 'Package marks', 35));
  }

  if (shipment.shipmentDetails.products.isAnyOtherRemarks) {
    shipmentDetailsCriteria.push(fieldRequired('shipmentDetails.products.otherRemarks', 'Other remarks'));
  }

  return shipmentDetailsCriteria;
}

function getValidationCriteriaForPackages(shipmentDetailsCriteria, shipment: Draft, carrier: string) {
  shipment.shipmentDetails.products.packages.forEach((item, index) => {
    shipmentDetailsCriteria.push(numberFieldRequired(`shipmentDetails.products.packages[${index}].weight`, 'Weight'));
    shipmentDetailsCriteria.push(measurementFieldBelowMinimum(`shipmentDetails.products.packages[${index}].weight`, 'WEIGHT'));

    if (!checkWeightIsUnderLimit(item.weight, carrier, shipment.measurementSystem)) {
      shipmentDetailsCriteria.push(
        fieldRequired(`shipmentDetails.products.packages[${index}].weight`, 'Weight', 'Weight limit is exceeded')
      );
    }

    shipmentDetailsCriteria.push(numberFieldRequired(`shipmentDetails.products.packages[${index}].height`, 'Height'));
    shipmentDetailsCriteria.push(measurementFieldBelowMinimum(`shipmentDetails.products.packages[${index}].height`, 'DIMENSION'));
    shipmentDetailsCriteria.push(numberFieldRequired(`shipmentDetails.products.packages[${index}].width`, 'Width'));
    shipmentDetailsCriteria.push(measurementFieldBelowMinimum(`shipmentDetails.products.packages[${index}].width`, 'DIMENSION'));
    shipmentDetailsCriteria.push(numberFieldRequired(`shipmentDetails.products.packages[${index}].length`, 'Length'));
    shipmentDetailsCriteria.push(measurementFieldBelowMinimum(`shipmentDetails.products.packages[${index}].length`, 'DIMENSION'));
    shipmentDetailsCriteria.push(
      numberFieldRequired(`shipmentDetails.products.packages[${index}].numberOfPackages`, 'Number of packages')
    );
  });

  return shipmentDetailsCriteria;
}

function getValidationCriteriaForInsurance(shipment: Draft) {
  let insuranceCriteria: any = [];

  if (shipment.isDocumentsShipment || shipment.isDomestic) return insuranceCriteria;

  if (shipment.additionalServices.doYouNeedInsurance) {
    const maxValue = shipment.shipmentDetails.products.declaredShipmentValue;

    insuranceCriteria.push(numberFieldRequired('additionalServices.insuranceValue', 'Insured value'));
    insuranceCriteria.push(numberFieldAboveMaximum('additionalServices.insuranceValue', 'Insured value',
      maxValue, `You cannot insure for more than the total shipment value of ${maxValue}`));
  }

  return insuranceCriteria;
}

function getValidationCriteriaForPickup(shipment: Draft, rates: Rate[]) {
  let pickupCriteria: any = [
    booleanFieldRequired('pickupDelivery.isPickupRequired', 'Pickup schedule'),
    fieldRequired('pickupDelivery.carrierProduct', 'Delivery Service'),
    selectedNotValidCarrierProduct('pickupDelivery.carrierProduct', rates)
  ];

  let isPickupRequired = shipment.pickupDelivery.isPickupRequired;

  if (FEATURE.PICKUP_OPTIONS && isPickupRequired === false) {
    pickupCriteria.push(fieldRequired('pickupDelivery.custom.pickupOption', 'Pickup option'));
  }

  return pickupCriteria;
}

function validatePickupToSave(carrier: string = '', pickupToSave: PickupData | undefined, carrierProduct: string | undefined) {
  const criteria: any = [];

  if (carrier && pickupToSave) {
    criteria.push(fieldLengthExceeded('notes', 'Notes', PICKUP_FORMAT[carrier].NOTES.MAX_LENGTH));
  }

  let result = validator(pickupToSave, criteria);

      // Check for same day FedEx Ground pickup
      if (carrierProduct === CARRIER.FED_EX_GROUND_PICKUP
        || carrierProduct === CARRIER.FED_EX_HOME_DELIVERY) {
        const pickupDateTime = pickupToSave?.date;
        if (pickupDateTime) {
          const pickupDate = new Date(Date.UTC(pickupDateTime.getFullYear(), pickupDateTime.getMonth(), pickupDateTime.getDate()));

          const now = new Date();
          const today = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));

          // eslint-disable-next-line
            if (pickupDate.getTime() == today.getTime()) {
              result.valid = false;
              result.errors[COMMON.ERROR_NOTIFICATIONS] = ['FedEx does not allow same day pickups for ground services. Please choose a different day for pickup or switch to an express service.'];
            }
        }
      }

      return result;
}

function getValidationCriteriaForPayment(shipment: Draft, paymentTime: string, carrier: string) {
  let paymentCriteria: any = [];

  if (FEATURE.PAYMENT_AUTH) {
    paymentCriteria.push(
      booleanValueExpected(
        'payment.termsAndConditionsAgreement',
        true,
        'Please indicate that you have read and agree to the Terms and Conditions.'
      )
    );

    if (shipment.isDutiable && paymentTime === PAYMENT_TIME.PAY_NOW) return paymentCriteria;
  }

  paymentCriteria.push(fieldRequired('payment.freightPayment', 'Payment freight'));

  if (shipment.isShipperAccountNumberRequired) {
    paymentCriteria.push(fieldRequired('payment.shipperAccountNumber', 'Account number'));
    paymentCriteria.push(fieldLengthExact('payment.shipperAccountNumber', 'Account', ACCOUNT_NUMBER_FORMAT[carrier].LENGTH));
    if (ACCOUNT_NUMBER_FORMAT[carrier].CONTAINS_ONLY_NUMBERS) {
      paymentCriteria.push(numberFieldRequired('payment.shipperAccountNumber', 'Account', 'Account should be a number'));
    }
  }

  if (shipment.isAssociatedZipCodeRequired) {
    paymentCriteria.push(fieldRequired('payment.billingAccountNumberPostalCode', 'Associated zip code'));
    paymentCriteria.push(fieldLengthExceeded('payment.billingAccountNumberPostalCode', 'Associated zip code', 12));
  }

  if (shipment.isDutiable) {
    paymentCriteria.push(fieldRequired('payment.dutiesAndTaxesPayment', 'Payment duties and taxes'));
    paymentCriteria.push(fieldRequired('payment.customsTermsOfTrade', 'Terms of trade'));

    if (shipment.isDutyAccountNumberRequired) {
      paymentCriteria.push(fieldRequired('payment.dutyAccountNumber', 'Third party account'));
      paymentCriteria.push(fieldLengthExact('payment.dutyAccountNumber', 'Third party account', ACCOUNT_NUMBER_FORMAT[carrier].LENGTH));
      if (ACCOUNT_NUMBER_FORMAT[carrier].CONTAINS_ONLY_NUMBERS) {
        paymentCriteria.push(numberFieldRequired('payment.dutyAccountNumber', 'Account', 'Account should be a number'));
      }
    }
  }

  return paymentCriteria;
}

function getTotalAddressLengthExceededCriteria(criteria: any, addressObject: any, maxLength: number | undefined, addressLinepath: string) {
  const addressLine1Length = addressObject.addressLine1.length;
  const addressLine2Length = addressObject.addressLine2 ? addressObject.addressLine2.length : 0;
  const addressLine3Length = addressObject.addressLine3 ? addressObject.addressLine3.length : 0;

  if (maxLength
    && addressLine1Length + addressLine2Length + addressLine3Length > maxLength) {
      const exceededBy = (addressLine1Length + addressLine2Length + addressLine3Length) - maxLength;
      criteria.push({
        field: `${addressLinepath}1`,
        test: obj => false,
        message: `The total number of characters in the three address lines is ${exceededBy} characters too long.`
      });
      criteria.push({
        field: `${addressLinepath}2`,
        test: obj => false,
        message: `The total number of characters in the three address lines is ${exceededBy} characters too long.`
      });
      criteria.push({
        field: `${addressLinepath}3`,
        test: obj => false,
        message: `The total number of characters in the three address lines is ${exceededBy} characters too long.`
      });
  }

  return criteria;
}

function validator(obj, criteria): ValidationResult {
  const cleanCriteria = criteria || [];

  const errors = cleanCriteria.reduce((errorObject, criterion) => {
    const {field, test, message, isWarning} = criterion;

    if (!test(obj)) {
      let errorItem: ValidationError = {
        message
      };

      if (isWarning) errorItem['isWarning'] = true;

      if (errorObject[field]) {
        errorObject[field].push(errorItem);
      } else {
        errorObject[field] = [errorItem];
      }
    }

    return errorObject;
  }, {});

  let isValid = true;

  for (let key of Object.keys(errors)) {
    for (let errorItem of errors[key]) {
      if (!errorItem.isWarning) {
        isValid = false;
      }
    }

    if (!isValid) break;
  }

  return {
    valid: isValid,
    errors
  };
}

function fieldRequired(path, name, message = '') {
  return {
    field: path,
    test: obj => !isEmpty(get(obj, path)),
    message: message || `${name} is required`
  };
}

function fieldLength(path, name, length = 3) {
  return {
    field: path,
    test: obj => get(obj, path)?.length >= length,
    message: `${name} must be at least ${length} characters`
  };
}

function fieldLengthExceeded(path, name, length) {
  return {
    field: path,
    test: obj => isEmpty(get(obj, path)) || get(obj, path)?.length <= length,
    message: `Maximum field length of ${length} for "${name}" was exceeded`
  };
}

function fieldLengthExact(path, name, length) {
  return {
    field: path,
    test: obj => isEmpty(get(obj, path)) || get(obj, path)?.length === length,
    message: `Length should be ${length} symbols`
  };
}

function emailCriteria(path) {
  return {
    field: path,
    test: obj => isValidEmail(get(obj, path)),
    message: 'Email does not seem to be valid'
  };
}

function phoneCriteria(path, name) {
  return {
    field: path,
    test: obj => isValidPhoneNumber(get(obj, path)),
    message: `${name} is not valid`
  };
}

function usPostalCodeCriteria(path) {
  return regexCriteria(
    path,
    USA_POSTAL_CODE_REG_EXP,
    'U.S. postal code should follow one of these formats: 99999 or 99999-9999.'
  );
}

function regexCriteria(path, regex, message) {
  return {
    field: path,
    test: obj => regex.test(get(obj, path)),
    message
  };
}

function booleanFieldRequired(path, name, message = '') {
  return {
    field: path,
    test: obj => isBoolean(get(obj, path, null)),
    message: message || `${name} is required`
  };
}

function booleanValueExpected(path, expectedValue, message) {
  return {
    field: path,
    test: obj => {
      let val = get(obj, path, null);
      if (!isBoolean(val)) return true;

      return val === expectedValue;
    },
    message
  };
}

function numberFieldRequired(path, name, message = '') {
  return {
    field: path,
    test: obj => {
      let val = Number(get(obj, path));
      return isNumber(val) && val > 0;
    },
    message: message || `${name} is required`
  };
}

function numberFieldAboveMaximum(path, name, maxValue, message ='') {
  return {
    field: path,
    test: obj => {
      let val = Number(get(obj, path));
      return isNumber(val) && val <= Number(maxValue);
    },
    message: message || `${name} must be less than or equal to ${maxValue}`
  };
}

function dateRequired(path, name, message = '') {
  return {
    field: path,
    test: obj => {
      let date = get(obj, path, null);

      if (isDate(date)) return true;

      return date;
    },
    message: message || `${name} is required`
  };
}

function measurementFieldBelowMinimum(path, field: 'WEIGHT' | 'DIMENSION') {
  const MEASUREMENT_FIELDS_MIN_VALUES = {
    WEIGHT: {
      name: 'Weight',
      value: 0.22
    },
    DIMENSION: {
      name: 'Dimension',
      value: 1
    }
  };

  const minValue = MEASUREMENT_FIELDS_MIN_VALUES[field];

  return numberFieldBelowMinimum(path, minValue.name, minValue.value);
}

function numberFieldBelowMinimum(path, name, minValue, message?) {
  return {
    field: path,
    test: obj => {
      let val = Number(get(obj, path));
      return isNumber(val) && val >= minValue;
    },
    message: message || `${name} value should be at least ${minValue}`
  };
}

function einFieldLengthExceeded(path, fieldLength, length = 9) {
  return {
    field: path,
    test: obj => isEmpty(get(obj, path)) || get(obj, path)?.length <= length,
    message: `Your EIN is too long by ${fieldLength - length} characters. Please shorten it to ${length} digits`
  };
}

function selectedNotValidCarrierProduct(path, rates: Rate[]) {
  return {
    field: path,
    test: obj => {
      const rate = rates.find(rate => rate.carrierService.productCode === get(obj, path));

      return rate ? true : false;
    },
    message: 'Delivery Service is required.'
  };
}

// Ensures user must select an invoice type (Commercial or Pro Forma)
// Previously allowed external invoices but not anymore
function checkForExternalInvoiceType(path, name) {
  return {
    field: path,
    test: obj => !(get(obj, path) === INVOICE_TYPE.EXTERNAL),
    message: `${name} is required`
  };
}
