import React from 'react';

function JustDocumentTooltip() {
  return (
    <span>
      If you are Shipping more <br /> than just a document(s) <br />
      you will need to Shipp it <br /> as a <strong>Product</strong>
    </span>
  );
}

export default JustDocumentTooltip;
