import React from 'react';
import {Row, Col} from 'components/bootstrap';

import formatHelper from 'helpers/formatHelper';
import dateHelper from 'helpers/dateHelper';

import SummarySection from './SummarySection';
import SummaryItem from '../../SummaryItem';

interface Props {
  shipment: Shipment;
  pickup?: Pickup;
}

function DetailsSummary({shipment, pickup}: Props) {
  function getPackageSpecsValue() {
    const shipmentDetails = shipment.shipmentDetails;

    if (shipment.isDocumentsShipment) {
      const displayDocumentItem = formatHelper.displayDocumentItem(
        shipmentDetails.documents,
        shipment.measurementSystem,
        shipment.isImport
      );

      return <div>{displayDocumentItem}</div>;
    }

    let piecesValues: string[] = [];

    shipmentDetails.products.packages.forEach((piece: Package) => {
      let displayPiece = formatHelper.displayPieceItem(piece, shipment.measurementSystem, true, shipment.isImport);

      piecesValues.push(displayPiece);
    });

    return (
      <>
        {piecesValues.map(val => {
          return <div key={val}>{val}</div>;
        })}
      </>
    );
  }

  function renderDomesticDetailsSummary(declaredValue, shipmentDate, declaredTotalValue) {
    const displayDeclaredValue = formatHelper.displayPrice(declaredValue || declaredTotalValue, shipment.currency);

    return (
      <SummarySection header="Shipment Details">
        <Row>
          <Col lg={6}>
            <SummaryItem label="Package specs" value={getPackageSpecsValue()} />
            <SummaryItem label="Declared Value" value={displayDeclaredValue} />
            <SummaryItem label="Shipment reference Nr." value={shipment?.shipmentReferenceNumber} />
            <SummaryItem label="Service" value={shipment?.carrier?.name} />
          </Col>
          <Col lg={6}>
            <SummaryItem label="Shipment Date" value={shipmentDate} />
            <SummaryItem label="Pickup Time" value={pickup?.displayPickupWindow} />
            <SummaryItem label="Location of pickup" value={pickup?.location} />
            <SummaryItem label="Note for Driver" value={pickup?.notes} />
          </Col>
        </Row>
      </SummarySection>
    );
  }

  function render() {
    const shipmentDetails = shipment.shipmentDetails;

    const declaredValue = shipment.isDocumentsShipment
      ? shipmentDetails.documents.declaredShipmentValue
      : shipmentDetails.products.declaredShipmentValue;

    const shipmentDate = dateHelper.displayShortDate(shipment?.targetShipDate);

    if (shipment.isDomestic) {
      return renderDomesticDetailsSummary(declaredValue, shipmentDate, shipment.declaredTotalValue);
    }

    return (
      <SummarySection header="Shipment Details">
        <Row>
          <Col lg={6}>
            <SummaryItem label="Package specs" value={getPackageSpecsValue()} />
            <SummaryItem label="Batteries" value={formatHelper.displayBooleanValue(shipment?.hasBatteries)} />
            <SummaryItem label="Declared Value" value={formatHelper.displayPrice(declaredValue, shipment.currency)} />
            <SummaryItem label="General Description" value={shipment?.description} />
            <SummaryItem
              label="Dangerous Goods"
              value={formatHelper.displayBooleanValue(shipment?.hasDangerousGoods)}
            />
            <SummaryItem label="Shipment reference Nr." value={shipment?.shipmentReferenceNumber} />
          </Col>

          <Col lg={6}>
            <SummaryItem label="Shipment Date" value={shipmentDate} />
            <SummaryItem label="Pickup Time" value={pickup?.displayPickupWindow} />
            <SummaryItem label="Location of pickup" value={pickup?.location} />
            <SummaryItem label="Note for Driver" value={pickup?.notes} />
            <SummaryItem label="Service" value={shipment?.carrier?.name} />
          </Col>
        </Row>
      </SummarySection>
    );
  }

  return render();
}

export default DetailsSummary;
